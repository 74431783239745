<template>
    <div v-animate-css="'fadeIn'" class="buttonSoport" @click.prevent="continueHelp()">
      <i class="fa-light fa-headset mr-2"></i>  {{ $t('app.t.p176') }}
    </div>
</template>
<script>
  export default {
    mounted(){

    },
    methods: {
        continueHelp(){
           window.open("https://wacheckin.tawk.help/", "_blank")
        }
    }
  }
</script>
<style lang="scss" scoped>
   .buttonSoport {
    background: #000000;
    color: #fff;
    position: fixed;
    z-index: 1000;
    padding-inline: 25px;
    padding-block: 5px;
    transform: rotate(-90deg);
    right: -32px;
    bottom: 0;
    top: 0;
    height: 39px;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition:0.4s;
    &:hover {
        transition:0.4s;
        padding-inline: 28px;
        padding-block: 6px;
    }
   }
</style>