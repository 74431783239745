export default {
    
    SET_USER_RETRIVE(state, response){
       console.log("SET_USER_RETRIVE")
       state.user_retrive = response;
    },

    SET_USER(state, result) {
        console.log("SET_USER")
        state.user = result;
    }
}