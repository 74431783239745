import  axios  from 'axios';
import { dominio } from '../../config/base';
const url = dominio;

export default {
    async getProgrammed({commit}, dataSend){
         try{
            const result = await axios.post(url + '/get-programmed-checkin', dataSend);
            commit('SET_PROGRAMMED', result.data);
         }catch(e){
            console.log(e);
         }
    },
    
    getClient({commit}, id_client){
        //   
    }

}