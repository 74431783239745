import stateCheckinProgrammed from './stateCheckinProgrammed';
import mutations from './mutations';
import actions from './actions';

export default {
  state: stateCheckinProgrammed,
  mutations,
  actions,
  namespaced: true
};
