export const profile = {
    text1: "My account information",
    text2: "Personal information",
    text3: "Name",
    text4: "ID number",
    text5: "Phone",
    text6: "Country",
    text7: "Email address",
    text8: "Update information",
    text9: "Access data",
    text10: "Password",
    text11: "Confirm password",
    text12: "Update password",

    // Corrections
    p1: "Account settings",
    p2: "Profile data",
    p3: "Login and security",
    p4: "Billing information",
    p5: "Primary email address",
    p6: "This is where you'll receive all communications from WACheckin",
    p7: "Account information",
    p8: "Manage your account data.",
    p9: "Name",
    p10: "Enter full name",
    p11: "ID number",
    p12: "Enter ID number",
    p13: "Phone",
    p14: "Enter phone number",
    p15: "Login and security",
    p16: "Update your password and secure your account.",
    p17: "Account password",
    p18: "Last updated:",
    p19: "Change",
    p20: "Billing information",
    p21: "Manage your account data.",
    p22: "Company name",
    p23: "Enter company name",
    p24: "Tax ID",
    p25: "Enter tax ID",
    p26: "Billing address",
    p27: "Enter billing address",
    p28: "Country",
    p29: "State",
    p30:'Zip code',
    p31:`No billing data`,
    p32:`Billing information is automatically uploaded when you subscribe.`,
};
