export const app_i18n = {
    text1: "Escanea la parte trasera de tu DNI o la hoja de tu pasaporte donde viene tu foto",
    text2: "¿Tienes problemas con el escáner?",
    text3: "Reintentar",
    text4: "Proceso manual",
    text5: "Bienvenido!!",
    text6: "Selecciona la fecha de hospedaje",
    text7: "Tu correo",
    text8: "Continuar",
    text9: "Sigamos!, falta poco",
    text10: "Bienvenida",
    text11: "Bienvenido",
    text12: "Teléfono",
    text13: "Para seguir con el proceso debes aceptar nuestros términos y condiciones.",
    text14: "Acepto los términos y condiciones",
    text15: 'Continuar',
    text16: "Puedes firmar en el recuadro gris que ves en pantalla",
    text17: "Limpiar",
    text18: "Guardar Firma",
    text19: "Por favor debes escribir tu firma",
    text20: "Check-in realizado con éxito",
    text21: "Muchas gracias, te hemos enviado copia de documento firmado a tu WhatsApp",
    text22: "Necesitas realizar otro check-in?",
    text23: "Volver a iniciar",
    text24: "Bienvenido  🤗",
    text25: "😃 Por favor ingresa los siguientes datos",
    text26: "Nombre",
    text27: "Apellidos",
    text28: "Seleccione el género",
    text29: "Sexo",
    text30: "Masculino",
    text31: "Femenino",
    text32: "Fecha de nacimiento",
    text33: "Sigamos!, ya falta poco 😉",
    text34: "Selecciona la fecha de hospedaje.",
    text35: "Correo electrónico",
    text36: "Ingresa ID",
    text37: "Tipo documento",
    text38: "ID",
    text39: "PASAPORTE",
    text40: "Ingresa número del documento",
    text41: "Debes seleccionar un país",
    text42: "Países",
    text43: "Listado de los servicios disponibles del alojamiento",
    text44: "Cargando servicios ...",
    text45: "No hay servicios disponibles",
    text46: "Solicitud de servicio enviada con éxito",
    text47: "Un agente se pondrá en contacto para concretar la solicitud de servicio.",
    text48: "Regresar",
    text49: "Solicitud de servicio enviada correctamente, en un momento sera contactado por un supervisor.",
    text50: "Precio",
    text51: "Descripción del servicio",
    text52: "Solicitar",
    text53: "Enviar la solicitud del servicio",
    text54: "Al enviar la solicitud un supervisor se pondrá en contacto contigo",
    text55: "Enviar",
    text56: "Solicitud de servicio enviada con éxito.",
    text57: "Error,  no se puedo solicitar el servicio",
    text58: "Enviando datos de Check-in por favor espere...",
    text59: "Cargando...",
    text60: "completó el check-in con éxito.",
    text61: `🎁 Haciendo click aquí podrás ver todos nuestros servicios disponibles en el hotel`,
    text62: "Registro de Entrada",
    text63: "Prefiero no decirlo",
    text64: "Otro",
    text65: "País",
    text66: "Error",
    text67: "Alojamiento desactivado.",
    text68: "El propietario desactivo los check-in.",
    text69: "Seleccionar fecha",
    text70: "Desde - Hasta",
    text71: "Selecciona una opción",
    text72: "Seleccione el país *",
    text73: "Seguro quieres reiniciar el proceso",
    text74: "Perderás los datos guardados",
    text75: "Datos del check-in",
    text76: "Firma del check-in",
    text77: "Firmado",
    text78: "Bienvenido",
    text79: "Lo sentimos, se ha producido un error al cerrar la sesión",
    text80: "Cancelar el proceso.",
    text81: "La creación del primer alojamiento es obligatorio para el buen funcionamiento del sistema.",
    text82: "Cancelar y Salir",
    text83: "Continuar con la edición",
    text84: "Buscar en los registros",
    text85: "No existen registros",
    text86: "Anterior",
    text87: "Siguiente",
    text88: "¿Quieres volver a intentar o hacer el proceso manual?",
    text89: "Cargando ...",
    text90: "Servicios",
    text91: "Fecha Emisión del documento",
    text92: "El campo F. Expedición del documento es obligatorio",
    p93: `loading your...`,
    p94: `Reservation Data`,
    p95: `Bienvenido`,
    p96: `Para continuar con el check-in por favor ingrese los siguientes datos:`,
    p97: `Este proceso es demostración, no se guardará ninguno de tus datos`,
    p98: `Código de seguridad:`,
    p99: `Código de seguridad del alojamiento`,
    p101: `Ingresa el código de seguridad`,
    p102: `El código de seguridad es incorrecto, por favor verifique e intente nuevamente.`,
    p103: `O también puedes`,
    p104: `Filtra por código de check-in`,
    p105: `Continuar`,
    p106: `Código del check-in`,
    p107: `Ingresa el código del check-in`,
    p108: `El código de check-in no está registrado en nuestra plataforma, por favor verifica e intenta de nuevo`,
    p109: `Ingresar código del alojamiento`,
    p110: `Check-in Ecológico`,
    p111: `¡Bienvenido!`,
    p112: `Por favor tenga a mano su`,
    p112_1:`DNI o Pasaporte`,
    p112_2:`para realizar el proceso`,
    p113: `Deberás aceptar los`,
    p114: ` permisos de la cámara 📷`,
    p115: ` son necesarios para que nuestro escáner funcione correctamente.`,
    p116: `Continuar`,
    p117: `Seleccionar idioma`,
    p118: `Ingresa el código de seguridad`,
    p119: `Tu hoja del pasaporte o la parte trasera de tu DNI`,
    p120: `cédula`,
    p121: `DNI`,
    p122: `o la hoja de tu pasaporte donde viene tu foto`,
    p123: `Para poder utilizar el scanner, es necesario conceder los permisos correspondientes a la cámara.`,
    p124: `Verificación de seguridad ​🔐`,
    p125: `Para verificar tu número hemos enviado un código a tu`,
    p126: `WhatsApp`,
    p127: `Escríbelo aquí:`,
    p128: `Ingresa el código`,
    p129: `Código incorrecto, por favor verifica y vuelve a intentarlo`,
    p130: `Regresar`,
    p131: `Continuar`,
    p132: `Fecha de entrada`,
    p133: `Fecha de salida`,
    p134: `Fecha de expiración`,
    p135: `Nacionalidad`,
    p136: `Al hacer click en`,
    p137: ` aceptas firmar el formulario con tu nombre, el contrato a corto pazo`,
    p138: `Políticas de privacidad`,
    p139: `Guardar firma`,
    p140: `Información de la reserva`,
    p141: `RESERVA:`,
    p142: `Entrada`,
    p143: `Salida`,
    p144: `Pagar`,
    p145: `Pagada`,
    p146: `Verificando pago`,
    p147: `Realizar Check-in`,
    p148: `Reserva`,
    p149: `Servicios`,
    p150: `VERIFICACIÓN DE IDENTIDAD`,
    p151: `Sube un sefie de tu cara, con buena luz  por favor`,
    p152: `En esta vista podras subir una foto por la parte <strong>frontal de tu DNI`,
    p153: `En esta vista podras subir una foto por la parte <strong>de atrás de tu DNI`,
    p154: `Abrir cámara`,
    p155: `Tomar de nuevo`,
    p156: `Verificar identidad`,
    p157: `Continuar`,
    p158: `Error al verificar su identidad.`,
    p159: `Por favor vuelva a intentar. Puede tomar las fotos en una zona ilumina y centre bien su documento.`,
    p160: `Proceso es obligatorio*`,
    p161: `Reintentar`,
    p162: `Verificando Identidad.`,
    p163: `Por favor espere ....`,
    p164: `Identidad verificada con éxito`,
    p165: `Ha verificado su identidad 🪪 correctamente, falta poco para completar el proceso de checkin 😎`,
    p166: `A consultar`,
    p167: `Para solicitar este servicio debes realizar tu checkin.`,
    p168: `Progreso`,
    p169: `Fecha de expiración del documento`,
    p170: `Fecha de entrada`,
    p171: `Fecha de salida`,
    p172: `Teléfono`,
    p173: `No se pudo escanear su documento.`,
    p174: `La dirección no pertenece a una reserva del sistema`,
    p175: `Información del check-in`,
    p176: `Soporte`,
    p177: `Español (España)`,
    p178: `Inglés`,
    p179: `Árabe`,
    p180: `DNI`,
    p181: `Pasaporte`,

    p182:`Selecciona el parentesco`,
    p183:`Hijo/a`,
    p184:`Abuelo/a`,
    p185:`Bisabuelo/a`,
    p186:`Bisnieto/a`,
    p187:`Cuñado/a`,
    p188:`Cónyuge`,
    p189:`Hermano/a`,
    p190:`Nieto/a`,
    p191:`Otro`,
    p192:`Padre o madre`,
    p193:`Sobrino/a`,
    p194:`Suegro/a`,
    p195:`Tío/a`,
    p196:`Tutor/a`,
    p197:`Yerno o nuera`,

    p198:`El campo parentesco es obligatorio`,
    p199:`ID`,
    p200:`OTRO`,
    p201:`Número de soporte del documento`,
    p202:`El número de soporte del documento es requerido`,

    p203:`Información requerida según el Real Decreto 933/2021`,
    p204:`Por el Real Decreto 933/2021, de 26 de octubre, necesitamos recopilar
      datos como su dirección, código postal, forma de pago y fecha de pago para
      cumplir con las normativas de seguridad.`,
    p205:`Dirección de domicilio actual`,
    p206:`Dirección`,
    p207:`Código postal`,
    p208:`Forma de pago`,
    p209:`Selecciona una forma de pago`,
    p210:`Efectivo`,
    p211:`Pago en destino`,
    p212:`Tarjeta de crédito`,
    p213:`Pago por Movil`,
    p214:`Plataforma de pago`,
    p215:`Tarjeta de regalo`,
    p216:`Transferencia`,
    p217:`Fecha de pago`,
    p218:`Forma de pago`,
    p219:`Fecha de pago`,
    p220:``,
    
   
    

};