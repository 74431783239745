export const account = {
  p1: `Manage your account`,
  p2: `Account settings`,
  p3: `Language`,
  p4: `Login and security`,
  p5: `My subscription`,
  p6: `Billing information`,
  p7: `WhatsApp Business`,
  p8: `Integrations`,
  p9: `Account settings`,
  p10: `Manage the contact information where you will receive all communications from WACheckin.`,
  p11: `Data updated successfully`,
  p12: `Platform language`,
  p13: `Select your preferred language for your control panel. This setting does not affect the check-in language your guests receive, as that depends on their device settings.`,
  p14: `Select language from the list`,
  p15: `Select language`,
  p16: `Spanish`,
  p17: `English`,
  p18: `Update language`,
  p19: `Language applied successfully`,
  p20: `Here you can update your password to keep your account secure.`,
  p21: `Do you want to change your password?`,
  p22: `Change password`,
  p23: `Check your email`,
  p24: `To continue with the password change, check your inbox and follow the steps`,
  p25: `Continue`,
  p26: `Oops! An unexpected error occurred`,
  p27: `Data updated successfully`,
  p28: `WhatsApp for Business`,
  p29: `Connect your WhatsApp Business account to our platform and send messages directly to your guest's from your personalized number.`,
  p30: `No account connected.`,
  p31: `Connect Account`,
  p32: `Account`,
  p33: `Connected`,
  p34: `Disconnect Account`,
  p35: `Loading information...`,
  p36: `Disconnect WhatsApp for Business`,
  p37: `Are you considering disconnecting WhatsApp for Business and stopping sending messages to your guest's through WhatsApp?`,
  p38: `Disconnect`,
  p39: `WhatsApp business account successfully disconnected`,
  p40: `WhatsApp business account could not be disconnected, please contact support`,
  p41: `Phone number`,
  p42: `Facebook account`,
  p43: `Business details`,
  p44: `You must have a valid and unique phone number to use it with the WhatsApp Business API.`,
  p45: `You will receive a verification code to authenticate your number on our platform.`,
  p46: `Your business phone number must be a valid number that meets the criteria of the`,
  p47: `WhatsApp for Business platform.`,
  p48: `You will receive a verification code to authenticate your number on our platform.`,
  p49: `Return to account status`,
  p50: `Continue`,
  p51: `You must have a personal Facebook account (linked to your business email)`,
  p52: `If you have created and connected a Facebook Business account to this system, you must authenticate it before starting the onboarding process by clicking Log in with Facebook.`,
  p53: `To authenticate your business account, open`,
  p54: `this link`,
  p55: `in a separate tab.`,
  p56: `You must generate a code in your authentication app and enter it in the "Enter code" field.`,
  p57: `Once confirmed, you can close this tab.`,
  p58: `Return to account status`,
  p59: `Return`,
  p60: `Continue`,
  p61: `Prepare your business details`,
  p62: `You must have your business information on hand: the legal name and address of your company, as well as the business account as you want it to be displayed.`,
  p63: `You must have a valid business website.`,
  p64: `You need to verify your account with Meta as soon as possible. A verified business can start by sending 1000 messages per day. If you do not verify your account, Meta will deactivate it.`,
  p65: `According to Meta's policy, an unverified business account can only be linked to two numbers. Verify the account to link it to more numbers.`,
  p66: `Return to account status`,
  p67: `Return`,
  p68: `Continue`,
  p69: `WhatsApp business account successfully connected`,
  
  p70: `Billing`,
  p71: `Status`,
  p72: `Amount Charged`,
  p73: `Paid`,
  p74: `Pending`,
  p75: `View Invoice`,
  p76: `No billing records found`,
  p77:`Date`,
  p78: ``,
  p79: ``,
  p80: ``,
};
