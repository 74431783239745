export const login_i18n = {
    text1: "Log in to your account",
    text2: "Welcome to QrCheck-in!",
    text3: "Enter your Email",
    text4: "Password",
    text5: "Email",
    text6: "Enter your Password",
    text7: "Remember",
    text8: "Forgot your password?",
    text9: "Don't have an account?",
    text10: "Sign up",
    text11: "Start",
    text12: "Sign in with Google",
    text13: "Country",
    text14: "Error",
    text15: "Select a language",
    text16: "Registered successfully",

    p1:`Recover Password`,
    p2:`I Forgot My Password`,
    p3:`A recovery link will be sent via email`,
    p4:`Email`,
    p5:`Recover`,
    p6:`Sent Successfully`,
    p7:`Check Your Inbox`,
    p8:`Email is Required`,
    p9:`Recovery Email Sent!`,
    p10:`Sign in to Your Account`,
    p11:`Enter Your Email`,
    p12:`Password`,
    p13:`Set a Password`,
    p14:`Continue`,

};