export const upsellings = {
    text1: "Servicios",
    text2: "Gestiona tus servicios",
    text3: "Lista de servicios",
    text4: "Proveedores",
    text5: "Lista de los servicios que verán tus clientes",
    text6: "Registrar servicio",
    text7: "Nombre",
    text8: "Creado",
    text9: "Estado",
    text10: "activo",
    text11: "inactivo",
    text12: "Editar servicio",
    text13: "Eliminar servicio",
    text14: "No se encontraron servicios.",
    text15: "Cambiado correctamente.",
    text16: "Seguro quieres borrar el servicio",
    text17: "Perderás los datos guardados",
    text18: "Eliminar servicio",
    text19: "Cancelar",
    text20: "Eliminado correctamente.",
    text21: "Crear servicio",
    text22: "Información del servicio",
    text23: "Introducir la información principal del servicio que se muestra en la tarjeta.",
    text24: "Todas los alojamientos",
    text25: "Selecciona el alojamiento *",
    text26: "Introducir nombre",
    text27: "Nombre de tu servicio *",
    text28: "Automática",
    text29: "Manual",
    text30: "Tipo de confirmación",
    text31: "Titulo del servicio",
    text32: "Ingresa el titulo *",
    text33: "Check-in/check-out",
    text34: "Traslado",
    text35: "F&B",
    text36: "Estacional",
    text37: "Familia",
    text38: "Romántico",
    text39: "SPA & Salud",
    text40: "Arte & Cultura",
    text41: "Actividades",
    text42: "Servicios",
    text43: "Otros",
    text44: "Categoría *",
    text45: "Internal service",
    text46: "Proveedor *",
    text47: "Descripción",
    text48: "Ingresa la descripción de tu servicio *",
    text49: "Precio",
    text50: "Seleccione el tipo de precio y defina cuánto pagarán sus huéspedes por la oferta.",
    text51: "Introducir número",
    text52: "Por unidad",
    text53: "Por persona",
    text54: "Por grupo",
    text55: "Por día",
    text555: "Por hora",
    text56: "Precio por",
    text57: "Imagen",
    text58: "Sube una imagen que será posicionada en la cabecera de la tarjeta.",
    text59: "Guardar servicio",
    text60: "Cargando ...",
    text61: "Servicio correctamente creado.",
    text62: "Listado de Proveedores",
    text63: "Añadir proveedor",
    text64: "ID",
    text65: "Nombre",
    text66: "Email",
    text67: "Teléfono",
    text68: "Editar proveedor",
    text69: "Eliminar proveedor",
    text70: "Registrar Proveedor",
    text71: "Agrega ",
    text72: "proveedores ",
    text73: "a tu lista para poder utilizarlos en la creación de nuevas ofertas.",
    text74: "Introducir nombre",
    text75: "Nombre del proveedor *",
    text76: "País",
    text77: "Error",
    text78: "Introducir su numero",
    text79: "Teléfono *",
    text80: "Correo electrónico *",
    text81: "Dirección",
    text82: "Añadir proveedor",
    text83: "Creado correctamente.",
    text84: "Seguro quieres borrar el proveedor?",
    text85: "Perderás los datos guardados",
    text86: "Eliminar proveedor",
    text87: "Eliminado correctamente.",
    text88: "Informes generados hasta el momento.",
    text89: "Total de ingresos",
    text90: "Mes actual",
    text91: "Hoy",
    text92: "Solicitudes rechazadas",
    text93: "Solicitudes aprobadas",
    text94: "Pagos confirmados",
    text95: "Ingresos totales de la plataforma",
    text96: "Gestiona tus suscripciones y facturas",

    // Correcciones  
    p1:`Precio a consultar`,
};