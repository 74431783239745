export const facturacion = {
    text1: "Method of payment",
    text2: "Credit Card",
    text3: "No registered payment methods",
    text4: "Edit your payment method",
    text5: "Add payment method",
    text6: "Add a payment method",
    text7: "Update a payment method",
    text8: "Register or update the payment method where monthly charges will be made.",
    text9: "Card number:",
    text10: "Exp",
    text11: "CVC",
    text12: "Save card",
    text13: "Invalid number",
    text14: "Invalid",
    text15: "Invalid CVC.",
    text16: "Something went wrong",
    text17: "Consumption",
    text18: "Invoices issued",
    text19: "From - To",
    text20: "ID",
    text21: "Value",
    text22: "Entity issuing the invoice",
    text23: "BIG NERDS COMPANY L.L.C.",
    text24: "Date of issue",
    text25: "State",
    text26: "Download Invoice",
    text27: "Generating Invoice ...",
    text28: "Almost finished please wait...",
    text29: "Loading ...",
    text30: "Sorry the invoice could not be generated",
    text31: "Are you sure you want to delete the payment method?",
    text32: "You will lose your saved data",
    text33: "Delete method",
    text34: "Cancel",

    // Corrections
    p1: "Subscription",
    p2: "Your Subscription",
    p3: "FREE",
    p4: "CUSTOM",
    p5: "You don't have an active subscription. Let's do something about it!",
    p6: "Expires:",
    p7: "Choose a plan",
    p8: "Upgrade",
    p9: "Number of properties",
    p10: "Per property",
    p11: "Manage subscription",
    p12: "Payment settings",
    p13: "Expires:",
    p14: "Edit",
    p15: "Auto-renewal",
    p16: "Activated",
    p17: "Next payment is scheduled for",
    p18: "Invoices and receipts",
    p19: "CUSTOM",
    p20: "View invoice",
    p21: "Download invoice",
    p22: "for",

    p23: "Monthly",
    p24: "Annual",
    p25: "We're giving you 2 months as a gift.",
    p26: "Subscription Type",


    p27: "Tourist Apartment",
    p28: "Hotel - Hostel",
    p29: "Campsite",
    p30: "Villa",


    p31: `The "<strong>Property</strong>" is based on individual houses or apartments. For "<strong>Aparthotel</strong>", each apartment counts as a single property`,
    p32: `It counts as
    <strong>Rooms</strong> in the case of hotels, as beds in the case of hostels,
    and as <strong>people </strong> in the case of camping.

    <br />
    <strong>For example:</strong> A hostel with 4 rooms and 20 beds will count as
    20 accommodation units`,

    p33: `PREMIUM`,
    p34: `month`,
    p35: `room`,
    p36: `apartment`,
    p37: `Plan Details`,
    p38: `On-site Check-in via QR`,
    p39: `Pre-sent Link Check-in`,
    p40: `Welcome Email Message`,
    p41: `Biometric Policy Signature`,
    p42: `ID - Passport Scanner`,
    p43: `Unlimited Cloud Storage`,
    p44: `Cross-selling Store`,
    p45: `Automatic Submission of Reports to Authorities`,
    p46: `PSM Reservation Synchronization`,
    p47: `Update Subscription`,
    p48: `Continue`,
    p49: `per`,

    p50: `Select additional services for your plan`,
    p51: `Recommended`,
    p52: `WhatsApp`,
    p53: `We invite your guests to check in via WhatsApp scheduled before they arrive at your accommodation.`,
    p54: `apartment`,
    p55: `villa`,
    p56: `plaza`,
    p57: `room`,
    p58: `Difference in Using WhatsApp`,
    p59: `Using Our WhatsApp`,
    p60: `When using our WhatsApp service, the sender name for messages sent to your guests will be WACheckin We handle message management to ensure effective communication with your guests.`,
    p61: `If you use our service with your own WhatsApp number, the sender name will be your WhatsApp name, and you can have your verification badge. The sending cost will be subject to your WhatsApp Business with Meta consumption.`,
    p62: `Using Your WhatsApp Business`,
    p63: `Ratings`,
    p64: `Seize this opportunity to enhance your guests' experience and garner more positive reviews!`,
    p65: `Benefits`,
    p66: `Upon checkout, we'll send your guests a rating survey via WhatsApp, significantly boosting your property's online reputation. Our service is designed to increase your positive reviews.`,
    p67: `Remote Access`,
    p68: `We send your guests a virtual key for autonomous property access.`,
    p69: `Benefits`,
    p70: `Your guests will securely and conveniently receive room access keys once they've completed check-in, providing them with a hassle-free experience and improving access management efficiency.`,
    p71: `Identity Verification`,
    p72: `We verify your guests' identity using our biometric comparison software.`,
    p73: `Benefits`,
    p74: `We verify your guests' identity using our biometric comparison software, ensuring their documentation matches the person accessing your accommodation.`,
    p75: `Subscribe now`,
    p76: `Unlimited cloud storage`,
    p77: `Customized`,
    p78: `Ask our sales team`,
    p79: `WhatsApp`,
    p80: `Identity verification`,
    p81: `Reviews (Check-in ratings)`,
    p82: `Connection with API for Smart Locks`,
    p83: `Charge Card (for deposits, services, etc)`,
    p84: `Connect with sales`,
    p85: ``,
    p86: ``,
    p87: ``,
    p88: ``,
    p89: ``,






};