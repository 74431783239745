export const upsellings = {
    text1: "Services",
    text2: "Manage your services",
    text3: "List of services",
    text4: "Suppliers",
    text5: "List of services your guests will see",
    text6: "Register service",
    text7: "Name",
    text8: "Created",
    text9: "State",
    text10: "active",
    text11: "inactive",
    text12: "Edit service",
    text13: "Delete service",
    text14: "No services were found.",
    text15: "Correctly changed.",
    text16: "Surely you want to delete the service",
    text17: "You will lose your saved data",
    text18: "Delete service",
    text19: "Cancel",
    text20: "Correctly eliminated.",
    text21: "Create service",
    text22: "Service Information",
    text23: "Enter the main service information shown on the card.",
    text24: "All properties",
    text25: "Select property *",
    text26: "Enter name",
    text27: "Name of your service *",
    text28: "Automatic",
    text29: "Manual",
    text30: "Type of confirmation",
    text31: "Title of service",
    text32: "Enter the title *",
    text33: "Check-in/check-out",
    text34: "Transfer",
    text35: "F&B",
    text36: "Seasonal",
    text37: "Family",
    text38: "Romantic",
    text39: "SPA & Health",
    text40: "Art & Culture",
    text41: "Activities",
    text42: "Services",
    text43: "Others",
    text44: "Category *",
    text45: "Internal service",
    text46: "Supplier *",
    text47: "Description",
    text48: "Enter your service description *",
    text49: "Price",
    text50: "Select the price type and define how much your guests will pay for the offer.",
    text51: "Enter number",
    text52: "Per unit",
    text53: "Per person",
    text54: "By group",
    text55: "Per day",
    text555: "Por hora",
    text56: "Price per",
    text57: "Image",
    text58: "Upload an image that will be positioned in the header of the card.",
    text59: "Save service",
    text60: "Loading ...",
    text61: "Service correctly created.",
    text62: "List of Suppliers",
    text63: "Add supplier",
    text64: "ID",
    text65: "Name",
    text66: "Email",
    text67: "Phone",
    text68: "Edit supplier",
    text69: "Remove supplier",
    text70: "Register Supplier",
    text71: "Add ",
    text72: "suppliers ",
    text73: "to your list so that you can use them to create new offers.",
    text74: "Enter name",
    text75: "Name of supplier *",
    text76: "Country",
    text77: "Error",
    text78: "Enter your number",
    text79: "Telephone *",
    text80: "E-mail address *",
    text81: "Address",
    text82: "Add supplier",
    text83: "Successfully created.",
    text84: "Are you sure you want to delete the supplier?",
    text85: "You will lose your saved data",
    text86: "Remove supplier",
    text87: "Correctly deleted.",
    text96: 'Manage your subscriptions and invoices',
    
    // Correcciones 
    p1: "Price to inquire",
};