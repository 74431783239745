const mutations = {
  retrieveToken(state, token) {
    state.token = token;
  },
  destroyToken(state) {
    state.token = null;
  },
  setUserGlobal(state, user) {
    // Validamos que el nuevo 'user' no sea nulo o vacío y que sea diferente del actual
    if (user && JSON.stringify(state.userRoot) !== JSON.stringify(user)) {
      state.userRoot = user; // Actualizamos el estado
      localStorage.setItem("user", JSON.stringify(user)); // Guardamos en localStorage
      console.log("Usuario actualizado en el estado y en localStorage.");
    } else {
      console.log("El usuario no ha cambiado o está vacío, no se actualiza.");
    }
  }
  
};

export default mutations;
