export const register_i18n = {
    text1: "Registrate",
    text2: "¡Registra tu cuenta!",
    text3: "Nombre",
    text9: "Registrar",
    text10: "Al crear una cuenta, aceptas nuestros",
    text11: "Policitcas de privacidad",
    text12: "y nuestras condiciones de uso.",
    text13: "Ya tienes cuenta?",
    text14: "Iniciar sesión",
    text15: "Confirmar Contraseña",

    // Correcciones  
    p1:`Regístrate ahora y ahorra tiempo y dinero automatizando las reservas de tus
    huéspedes`,
    p2:`Prueba gratis`,
    p3:`¡Disfruta de 14 créditos gratis para probar WACheckiny descubrir cómo podemos ayudarte! Aprovecha esta oportunidad para experimentar nuestro servicio.`,
    p4:`Regístrate en dos simples pasos`,
    p5:`Incluye tus datos personales y cuéntanos un poco sobre tu/tus propiedades.`,
    p6:`No requerimos tarjeta de crédito`,
    p7:`Puedes disfrutar del trial sin compromiso. Preocúpate solo de disfrutar de nuestra plataforma!`,
    p8:`Regístrate ahora y ahorra tiempo y dinero.`,
    p9:`Nombre`,
    p10:`Email`,
    p11:`Introduce tu email`,
    p12:`Contraseña`,
    p13:`Establece una contraseña`,
    p14:`Teléfono`,
    p15:`Tipo de propiedad`,
    p16:`Selecciona el tipo`,
    p17:`Apartamento turístico`,
    p18:`Número de habitaciones aprox.`,
    p19:`Selecciona el rango`,
    p20:`Continuar`,
    p21:`Código del asesor (opcional)`,
    p22:`Código invalido`,
    p23:`El código de asesor no es correcto. Por favor, ingrese uno válido o deje el campo vacío.`,
};