<template>
  <div class="by-loader">
    <span class="loader"></span>
    <strong style="
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        text-align: center;
        bottom: 38%;
        color: #333;
      ">
      {{ message || $t("app.t.text89") }}
    </strong>
  </div>
</template>
<script>
export default {
  props: ['message'],
  mounted() { },
};
</script>
<style lang="scss" scoped>
.by-loader {
  background: #ffffffe8;
  position: absolute;
  z-index: 10000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border: 5px solid;
  border-color: #11b14d transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
