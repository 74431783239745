var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",staticStyle:{"text-align":"left"},attrs:{"id":"modalLenguaje","tabindex":"-1","aria-labelledby":"staticBackdropLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg"},[_c('div',{staticClass:"modal-content",staticStyle:{"padding":"28px"}},[_c('div',{staticClass:"modal-header",staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":"/img/marca/panda9.svg","alt":""}}),_c('h4',{staticStyle:{"font-size":"23px","color":"#000"}},[_vm._v(_vm._s(_vm.$t('empresa.maqueta.p12')))]),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('strong',[_vm._v(_vm._s(_vm.$t('empresa.maqueta.p13')))]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col-12 col-lg-3",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"slectIdioma card",staticStyle:{"padding":"16px","font-weight":"600"},style:(_vm.$store.state.idioma == 'es'
                  ? { color: '#3F51B5', 'font-weight': 700 }
                  : {}),on:{"click":function($event){$event.preventDefault();return _vm.changeLanguage('es')}}},[_vm._v(" "+_vm._s(_vm.$t("app.t.p177"))+" ")])]),_c('div',{staticClass:"col-12 col-lg-3",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"slectIdioma card",staticStyle:{"padding":"16px","font-weight":"600"},style:(_vm.$store.state.idioma == 'en'
                  ? { color: '#3F51B5', 'font-weight': 700 }
                  : {}),on:{"click":function($event){$event.preventDefault();return _vm.changeLanguage('en')}}},[_vm._v(" "+_vm._s(_vm.$t("app.t.p178"))+" ")])]),_c('div',{staticClass:"col-12 col-lg-3",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"slectIdioma card",staticStyle:{"padding":"16px","font-weight":"600"},style:(_vm.$store.state.idioma == 'ar'
                  ? { color: '#3F51B5', 'font-weight': 700 }
                  : {}),on:{"click":function($event){$event.preventDefault();return _vm.changeLanguage('ar')}}},[_vm._v(" "+_vm._s(_vm.$t("app.t.p179"))+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",staticStyle:{"margin-top":"-154px"},attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('i',{staticClass:"far fa-times",staticStyle:{"font-size":"20px"}})])
}]

export { render, staticRenderFns }