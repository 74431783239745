import stateUser from './stateUser';
import mutations from './mutations';
import actions from './actions';

export default {
  state: stateUser,
  mutations,
  actions,
  namespaced: true
};
