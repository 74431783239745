export const login_i18n = {
    text1: "Inicia sesión en tu cuenta",
    text2: "¡Te damos la bienvenida a QrCheck-in!",
    text3: "Introduce tu Email",
    text4: "Contraseña",
    text5: "Email",
    text6: "Introduce tu Contraseña",
    text7: "Recordar",
    text8: "¿Olvidaste tu contraseña?",
    text9: "¿No tienes una cuenta?",
    text10: "Registrate",
    text11: "Iniciar",
    text12: "Iniciar con Google",
    text13: "Pais",
    text14: "Error",
    text15: "Selecciona un idioma",
    text16: "Registrado correctamente",

    // recuperar  
    p1:`Recuperar contraseña`,
    p2:`Olvide mi contraseña`,
    p3:`Se enviará un correo electrónico con el enlace de recuperación`,
    p4:`Correo electrónico`,
    p5:`Recuperar`,
    p6:`Se envió correctamente`,
    p7:`Revisa tu bandeja de entrada`,
    p8:`El correo electrónico es obligatorio`,
    p9:`Correo de recuperacion enviado!`,
    p10:`Inicia sesión en tu cuenta`,
    p11:`Introduce tu email`,
    p12:`Contraseña`,
    p13:`Establece una contraseña`,
    p14:`Continuar`,
    p15:``,
    p16:``,
    p17:``,
    p18:``,
    p19:``,
    p20:``,
};