import { io } from 'socket.io-client';

class SocketioService {
    socket;
    constructor() {}

    setupSocketConnection() {
        this.socket = io(`${process.env.VUE_APP_SOCKET_ENDPOINT}`, {
            transports: ['websocket'], // Forzar WebSocket
            reconnectionAttempts: 5,   // Intentos de reconexión
            reconnectionDelay: 1000,   // Retraso entre intentos
        });
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}

export default new SocketioService();
