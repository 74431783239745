<template>
    <div class="tabBottom">
         <!-- .menu para los botones  -->
         <div class="botonesList d-flex justify-content-between">
             <div class="bloqueBT" :class="{'active': $route.name =='Bookings'}" @click.prevent="cambiarRuta('Bookings')">
                <i class="fas fa-check-circle"></i>
                <div class="di">Check-ins</div>
             </div>
             <div class="bloqueBT" :class="{'active': $route.name =='Reservas'}" @click.prevent="cambiarRuta('Reservas')">
                <i class="fa-solid fa-clock"></i>
                <div class="di">Check-ins Prog.</div>
             </div>
             <div class="bloqueBT" :class="{'active': $route.name =='Servicios'}" @click.prevent="cambiarRuta('Servicios')">
                <i class="fas fa-headset"></i>
                <div class="di">Peticiones</div>
             </div>
         </div>
    </div>
</template>
<script>
 export default {
    methods: {
        cambiarRuta(ruta){
        if(this.$route.name != ruta){ 
             this.$router.push({name: ruta})
  
        }
        }
    }
 }
</script>
<style lang="scss" scoped>
    .tabBottom {
        position: fixed;
    bottom: 0;
    height: 70px;
    z-index: 10;
    width: 100%;
    padding: 10px 29px;
    background: white;
    box-shadow: 0px 0px 5px 2px #eee;

        i{
            font-size: 20px;
        }
    }

    .di {
        text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    }

    .bloqueBT.active {
       .di {
        color: green !important;
       }
       i {
        color: green !important;
       }
    }

    
    
</style>