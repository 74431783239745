export const servicios = {
    text1: "Listado de peticiones de servicio",
    text2: "Peticiones realizadas por tus clientes",
    text3: "Listado de Peticiones",
    text4: "Todos",
    text5: "Pendiente",
    text6: "Aprobado",
    text7: "Rechazado",
    text8: "Pagado",
    text9: "Servicio",
    text10: "Cliente",
    text11: "Teléfono",
    text12: "Creado",
    text13: "Estado",
    text14: "Ver pedido",
    text15: "Solicitud de servicio.",
    text16: "Datos de la solicitud",
    text17: "Fecha de la solicitud:",
    text18: " a las",
    text19: "Información del servicio",
    text20: "Precio:",
    text21: "Precio por:",
    text22: "Categoría:",
    text23: "Proveedor:",
    text24: "Proveedor:",
    text25: "Internal Service",
    text26: "Información del servicio",
    text27: "Servicio no encontrado",
    text28: "Información del solicitante",
    text29: "Teléfono",
    text30: "Correo electrónico:",
    text31: "Tipo de documento:",
    text32: "Numero de documento:",
    text33: "Cerrar",
    text34: "Rechazar",
    text35: "Aprobar",
    text36: "Confirmar Pago",
    text37: "Rechazar solicitud",
    text38: "Aprobar solicitud",
    text39: "Confirmar pago del solicitante",
    text40: "¿Estas seguro de rechazar la solicitud de servicio?",
    text41: "Comunícate antes con el solicitante de servicio para aprobar la solicitud",
    text42: "Al confirmar el pago del solicitante el proceso estará completo",
    text43: "Rechazar",
    text44: "Aprobar solicitud",
    text45: "Ya confirme el pago",
    text46: "Cancelar",
    text47: "Actualizado correctamente.",

    // Nuenas etiquetas  

    p1:`Hoy`,
    p2:`Servicio solicitado`,
    p3:`Huésped`,
    p4:`Fecha de solicitud`,
    p5:`Aún no hay solicitudes de servicios realizadas`,
    p6:`Aún no has configurado ningún servicio.`,
    p7:`Configura los servicios de tu alojamiento para que tus huéspedes puedan realizar sus solicitudes`,
    p8:`Configurar servicio`,
    p9:`Consulta`,
    p10:`Descartar solicitud de servicio`,
    p11:`¿Estás seguro? Los datos de la solicitud se perderán`,
    p12:`Descartar`,
    p13:`Solicitud de servicio eliminado con éxito`,
    p14: `Sube máximo 3 fotos de tu servicio`,
    p15:`Arrastra y suelta para subir las fotos`,
    p16:`...o haz click para seleccionar las fotos desde tu computadora`,
    p17:`Clic para buscar imagen de tu galería`,
    p18:``,
    p19:``,
    p20:``,
    p21:``,
};