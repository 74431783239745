import { home_i18n } from "./views/empresa/home.js";
import { login_i18n } from "./views/auth/login";
import { register_i18n } from "./views/auth/register.js";
import { app_i18n } from "./views/appWeb.js";
import { footer_i18n } from "./views/footer.js";
import { listbookings } from "./views/empresa/ListBookings.js";
import { checkinmanual } from "./views/empresa/checkinmanual.js";
import { enviarcheckin } from "./views/empresa/enviarcheckin.js";
import { servicios } from "./views/empresa/servicios.js";
import { propiedades } from "./views/empresa/propiedades.js";
import { upsellings } from "./views/empresa/upsellings.js";
import { integraciones } from "./views/empresa/integraciones.js";
import { facturacion } from "./views/empresa/facturacion.js";
import { profile } from "./views/empresa/profile.js";
import { maqueta } from "./views/empresa/maqueta.js";
import { noti } from "./views/empresa/noti.js";
import { reservas } from "./views/empresa/reservas.js";
import { nav } from "./views/empresa/nav.js";
import { account } from "./views/empresa/account.js";
import { settingAccommodation } from "./views/empresa/settingAccommodation.js";
import { suscription } from "./views/empresa/suscription.js";
import { notifications } from "../en/views/empresa/notifications.js";


export const en_i18n = {
  empresa: {
    home: home_i18n,
    listbookings: listbookings,
    checkinmanual: checkinmanual,
    enviarcheckin: enviarcheckin,
    servicios: servicios,
    propiedades: propiedades,
    upsellings: upsellings,
    integraciones: integraciones,
    facturacion: facturacion,
    profile: profile,
    maqueta: maqueta,
    noti: noti,
    reservas: reservas,
  },
  lg: {
    nav: nav,
    account:account,
    acc: settingAccommodation,
    sus: suscription,
    nt: notifications
  },
  auth: {
    login: login_i18n,
    register: register_i18n,
  },
  footer: {
    t: footer_i18n,
  },
  app: {
    t: app_i18n,
  },
};
