<template>
<div>
    <div v-if="!userRoot.cuenta_confirmada && !$isMobile" v-animate-css="'fadeIn'" class="alertaConfirmacion messagelg">
        <div class="confirmaC d-flex flex-row" style="align-items: center; padding-top: 2px">
            <strong> {{ $t("empresa.maqueta.p7") }}</strong>

            <small class="ml-4"> {{ $t("empresa.maqueta.p8") }}</small>

            <small class="ml-3">
                <a style="color: #fff" v-if="!send_email" href="#" @click="forwardMail()"><strong>{{ $t("empresa.maqueta.p9") }}</strong>
                </a>
                <a v-if="send_email" href="#" style="color: #fff">{{
              $t("empresa.maqueta.p10")
            }}</a>
            </small>
        </div>
    </div>

    <div v-if="!userRoot.cuenta_confirmada && $isMobile" v-animate-css="'fadeIn'" class="alertaConfirmacion messagexs">
        <div class="confirmaC d-flex flex-row" style="align-items: center; padding-top: 2px">
            <strong> {{ $t("empresa.maqueta.p7") }}</strong>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'messageConformationAccount',
    computed: {
        ...mapState(['userRoot'])
    },
    data() {
        return {
            send_email: false,
        }
    },
    methods: {
        async forwardMail() {
            this.send_email = true;
            let url = this.$base.dominio + "/forward-confirmation-email";
            const sendData = {
                user_id: this.$store.state.userRoot.id,
            };

            try {
                const response = await axios.post(url, sendData);
                if (response) {
                    this.send_email = false;
                    // eslint-disable-next-line no-undef
                    toastr.success(this.$t('lg.nav.p8'));
                }

            } catch (error) {
                this.send_email = false;
                // eslint-disable-next-line no-undef
                toastr.success("Error processing the request");
            }

        },
    }

}
</script>

<style lang="scss" scoped>
.messagelg {
    background: #191919;
    color: #dedede;
    padding-top: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
    padding-left: 121px !important;
    top: -33px;
    left: 0px;
    position: absolute;
    right: 0px;
}

.messagexs {
    background: #191919;
    color: #dedede;
    padding-top: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
    padding-left: 121px !important;
    top: -33px;
    left: 0px;
    position: absolute;
    right: 0px;
}
</style>
