export const integraciones = {
    text1: "Integrations.",
    text2: "Contact the developers to make some integration with your",
    text3: "PMS",
    text4: "Contact",
    text5: "Request Integration",
    text6: "Data collected",
    text7: "Applicant Information",
    text8: "Name",
    text9: "Reason for request",
    text10: "Write down the reason why you need integration",
    text11: "Close",
    text12: "Send integration request",
    text13: "Correctly registered",
    text14: "Request error",
};