<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalWhatsapp"
    style="text-align: left"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" style="padding: 28px">
        <div class="modal-header" style="align-items: center">
          <img src="/img/marca/panda13.svg" alt="" style="width: 100px" />
          <h4 style="font-size: 27px;
    color: rgb(0, 0, 0);
    width: 50%;
    margin-left:20px;
    font-weight: 600;">
           
            Acceso exclusivo a WhatsApp Business <img src="/img/whatsapp.png" alt="" style="width: 20px; margin-left: 4px" />
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="margin-top: -154px"
          >
            <i class="far fa-times" style="font-size: 20px"></i>
          </button>
        </div>
        <div class="modal-body">
          <br />
          <p style="font-size: 16px; font-weight: 400">
            Para acceder a la función de WhatsApp Business, se requiere una suscripción
            activa a WhatsApp Business Personal. Esta opción está diseñada para ofrecerte
            herramientas adicionales y funciones avanzadas para potenciar tu experiencia
            comercial.
          </p>
          <br />
<br>
          <!-- <template>
            <button class="btn btn-success" style="    font-size: 17px;
    float: right;">Suscripciones  <img
            src="/img/marca/corona.png"
            alt=""
            style="
              width: 20px;
              position: absolute;
              right: 2px;
              top: -5px;
              transform: rotate(36deg);
            "
          /></button>
          </template> -->
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.slectIdioma {
  cursor: pointer;
  &:hover {
    background: #eeeeeec1;
    transition: 0.4s;
  }
}
</style>
