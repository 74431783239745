import axios from 'axios';
import { dominio } from '../../config/base';
const url = dominio;

export default {
    async countCheckin({ commit }, property_id) {
      if(!property_id) return;
      // Simula una llamada a una API para obtener check-ins
      const response = await axios.get(url + '/count-checkin-property/'+property_id);
      commit('SET_COUNT_CHECKINS', response.data);
      return response.data;
    }
  };
  