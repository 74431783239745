import stateProperty from './stateProperty';
import mutations from './mutations';
import actions from './actions';

export default {
  state: stateProperty,
  mutations,
  actions,
  namespaced: true
};
