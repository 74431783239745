export const home_i18n = {
    text1: "Envía cheking a firmar",
    text2: "El cheking virtual recolecta los datos biométricos del huesped y lo vincula con su reserva",
    text3: "Enviar Cheking",
    text4: 'Inicio',
    text5: "Check-in",
    text6: "Bienvenido",
    text7: "Configuración check in",
    text8: "Mi cuenta",
    text9: "Configuración check in",
    text10: 'Cerrar',
    text11: "Imagen del Hotel",
    text12: "Políticas del alojamiento",
    text13: "Mensajes bienvenida",
    text14: "Sube el logo de tu Hotel",
    text15: "Selecciona o arrastra el logo",
    text16: "Cancelar",
    text17: "Nombre del Hotel",
    text18: "Guardar cambios",
    text19: "Cambiar el logo",
    text20: "Descargar",
    text21: "Historial de firmas",
    text22: "Panel de control de los documentos firmados por los clientes.",
    text23: "Filtro:",
    text24: "Desactivado",
    text25: "Por fechas",
    text26: "Por pais",
    text27: "Nombre",
    text28: "Apellidos",
    text29: "Nacionalidad",
    text30: "Fecha de Check-in",
    text31: "Sincronizado",
    text32: "Opciones",
    text33: "Sin datos",
    text34: "Filas por página:",
    text35: "Buscar",
    text36: "Paises",
    text37: "Desde",
    text38: "Hasta",
    text39: "COPYRIGHT © 2022 , Todos los derechos reservados WACheckines",
    text40: "Hecho con",
    text41: "Exportar",
    text42: "Ver detalle",
    text43: "Ver PDF firmado",
    text44: "Borrar",
    text45: "Selecciona una opción",
    // aca
    text46: "Renovar suscripción",
    text47: "Registre o actualice el método de pago en el que se efectuarán los cargos.",
    text48: "Deuda total",
    text49: "Pago exitoso",

    p1:`Estás en la versión de prueba.`,
    p2:`Te quedan`,
    p3:`días disponibles.`,
    p4:`El período de prueba ha finalizado.`,
};