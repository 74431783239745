window.$ = window.jQuery = require('jquery');
import Vue from 'vue'
import './plugins/axios'
import deviceDetector from './plugins/device-detector';



import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


import App from './App.vue'
import router from './router'
import store from './store/store'



import VueSocialauth from 'vue-social-auth'
Vue.use(VueSocialauth, {
    providers: {
        google: {
            clientId: '852672689111-20ahekng1pml7ra37i0k1ac53eufvp8d.apps.googleusercontent.com',
            client_secret: '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',
            redirectUri: 'https://app.wacheckin.com/callback'
        }
    }
});


// VeeValidate
import VeeValidate from 'vee-validate';
import { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es.js";
import en from "vee-validate/dist/locale/en.js";
import ar from "vee-validate/dist/locale/ar.js"; // Árabe estándar

Vue.use(VeeValidate);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import { translations } from "./i18n/translations.js";
const messages = translations;


// Detectar el idioma del navegador
let language = (navigator.language || navigator.browserLanguage || 'en').substr(0, 2);
let locale = 'es'; // Establece español por defecto

if (language === 'en') {
  locale = 'en';
  Validator.localize('en', en);
} else if (language === 'es') {
  locale = 'es';
  Validator.localize('es', es);
} else if (language === 'ar' || store.state.idioma === 'ar') {
  locale = 'ar'; // Configura árabe estándar
  Validator.localize('ar', ar); // Cargar la localización árabe estándar
} else {
  locale = 'en';
  Validator.localize('en', en);
}

const storedLanguage = localStorage.getItem("appLanguage") || locale;

store.state.idioma = storedLanguage;

const i18n = new VueI18n({
    locale: storedLanguage, // set locale
    messages, // set locale messages
});


Vue.prototype.$language = i18n; // Agrega la instancia de VueI18n a Vue.prototype



// alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Croppa
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
Vue.use(Croppa)


import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
window.toastr = require('toastr')
Vue.use(VueToastr2)

// eslint-disable-next-line no-undef
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}



import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)



import VTooltip from 'v-tooltip'
Vue.use(VTooltip)


import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);

import VueCountryCode from "vue-country-code";
Vue.use(VueCountryCode);

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.snow.css";
Vue.use(VueQuillEditor);


import "vue-phone-number-input/dist/vue-phone-number-input.css";


import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');

Vue.prototype.moment = moment;



Vue.config.productionTip = false

// Variables globales 
import * as tb from "./config/base";
Vue.prototype.$base = tb;

import * as fn from "./config/funtionsglobal";
Vue.prototype.$funtion = fn;

import VueCardFormat from 'vue-credit-card-validation';
Vue.use(VueCardFormat);

import Notifications from 'vue-notification'
Vue.use(Notifications)


import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
Vue.use(Skeleton)


import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);


import Ripple from 'vue-ripple-directive';
Vue.directive('ripple', Ripple);


import PrimeVue from 'primevue/config';
Vue.use(PrimeVue, {ripple: true});
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import ToastService from 'primevue/toastservice';
Vue.use(ToastService);

import ConfirmationService from 'primevue/confirmationservice';
Vue.use(ConfirmationService);

// Plugin para verificar el tamaño 
Vue.use(deviceDetector);


new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')