export const register_i18n = {
    text1: "Sign up",
    text2: "Register your account!",
    text3: "Name",
    text9: "Register",
    text10: "By creating an account, you agree to our",
    text11: "Privacy Policy",
    text12: "and our Terms of Service.",
    text13: "Already have an account?",
    text14: "Sign in",
    text15: "Confirm Password",

    // Corrections
    p1: "Sign up now and save time and money by automating your guests' bookings",
    p2: "Free trial",
    p3: "Enjoy 14 free credits to try WACheckinand discover how we can help you! Take advantage of this opportunity to experience our service.",
    p4: "Register in two simple steps",
    p5: "Include your personal information and tell us a little about your property/properties.",
    p6: "We do not require a credit card",
    p7: "You can enjoy the trial without commitment. Just worry about enjoying our platform!",
    p8: "Sign up now and save time and money.",
    p9: "Name ",
    p10: "Email ",
    p11: "Enter your email",
    p12: "Password ",
    p13: "Set a password",
    p14: "Phone ",
    p15: "Type of property",
    p16: "Select the type",
    p17: "Tourist apartment",
    p18: "Approximate number of rooms",
    p19: "Select the range",
    p20: "Continue",
    p21: "Advisor Code (optional)",
    p22: "Invalid Code",
    p23: "The advisor code is not correct. Please enter a valid one or leave the field empty."
};
