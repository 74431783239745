export const suscription = {
    p1:`Gestiona los detalles de tu plan actual y explora opciones para actualizar o cambiar tu suscripción.`,
    p2:`Plan Anual`,
    p3:`Plan Mensual`,
    p4:`Activo hasta`,
    p5:`Por habitación mensual`,
    p6:`Por alojamiento mensual`,
    p7:`Numero de aforo`,
    p8:`Por aforo mensual`,
    p9:`Actualizar suscripción`,
    p10:`Servicios activos`,
    p11:`Activo`,
    p12:`No activo`,
    p13:`Invita a tus huéspedes a realizar el check-in mediante WhatsApp, programandolo antes de su llegada al alojamiento.`,
    p14:`Valoraciones`,
    p15:`Invita a tus huéspedes a calificar su experiencia y mejora la reputación de tu alojamiento con valoraciones positivas.`,
    p16:`Acceso remoto`,
    p17:`Registra y gestiona las habitaciones equipadas con cerradura inteligente. Configura los códigos de acceso que se proporcionarán a los huéspedes.`,
    p18:`Verificación de identidad`,
    p19:`Verificamos la identidad de los huéspedes comparando la fotografía de su documento de identidad o pasaporte con un selfie en tiempo real.`,
    p20:`Administrar suscripción y métodos de pago`,
    p21:`Agrega o elimina tarjetas según sea necesario`,
    p22:`Tu suscripción actual es gratuita`,
    p23:`Actualiza tu suscripción para acceder a características avanzadas.`,
    p24:`Tu suscripción gratuita expira en`,
    p25:`dia`,
    p26:`días`,
    p27:`No pierdas acceso a funciones avanzadas; actualiza ahora tu suscripción`,
    p28:`Personaliza tu plan con servicios adicionales`,
    p29:`aforo`,
    p30:`mes`,
    p31:`Valoraciones`,
    p32:`Acceso remoto`,
    p33:`carpa`,
    p34:`villa`,
    p35:`Verificación de identidad`,
    p36:`AFORO TOTAL`,
    p37:`Aforo total se refiere a la capacidad máxima de personas permitidas en todos tus Apartamentos, ejemplo, (si seleccionas 5 apartamentos tu aforo total puede ser 20 plazas). Al proporcionar el aforo total, nos ayuda a gestionar su suscripción de manera precisa y a cumplir con las regulaciones de seguridad.`,
    p38:`Aforo total se refiere a la capacidad máxima de personas permitidas en tu Villa, ejemplo, (si seleccionas 5 apartamentos tu aforo total puede ser 20 plazas). Al proporcionar el aforo total, nos ayuda a gestionar su suscripción de manera precisa y a cumplir con las regulaciones de seguridad.`,
    p39:`Aforo total se refiere a la capacidad máxima de personas permitidas en tu Hotel - Hostal, ejemplo, (si seleccionas 5 apartamentos tu aforo total puede ser 20 plazas). Al proporcionar el aforo total, nos ayuda a gestionar su suscripción de manera precisa y a cumplir con las regulaciones de seguridad.`,
    p40:`Aforo total se refiere a la capacidad máxima de personas permitidas en tu Camping, ejemplo, (si seleccionas 5 apartamentos tu aforo total puede ser 20 plazas). Al proporcionar el aforo total, nos ayuda a gestionar su suscripción de manera precisa y a cumplir con las regulaciones de seguridad.`,
    p41:`Debes ingresar el aforo total de tus propiedades.`,
    p42:`WhatsApp de WACheckin`,
    p43:`Tu WhatsApp Business`,
    p44:`por aforo/mensual`,
    p45:`por habitación/mensual`,
    p46:`Anual`,
    p47:`Activar suscripción`,
    p48:`Actualizar suscripción`,
    p49:`Suscripción seleccionada`,
    p50:`Número de`,
    p51:`habitación`,
    p52:`alojamiento`,
    p53:`Precio mensual por`,
    p54:`habitaciones`,
    p55:`alojamientos`,
    p56:`Número de plazas seleccionadas`,
    p57:`Precio mensual por`,
    p58:`plaza`,
    p59:`Tipo de suscripción`,
    p60:`Anual`,
    p61:`Mensual`,
    p62:`Servicios adicionales:`,
    p63:`Sin servicios agregados`,
    p64:`Se recomienda agregar el servicio de WhatsApp`,
    p65:`Valoraciones`,
    p66:`Total a pagar`,
    p67:`Continuar con el pago`,
    p68:`Actualizar Suscripción`,
    p69:``,
    p70:``,
    p71:``,
    p72:``,
}