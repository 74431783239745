var render = function render(){var _vm=this,_c=_vm._self._c;return (
            _vm.demo &&
            _vm.user &&
            _vm.user?.servicios &&
            !_vm.user?.suscription_id_stripe &&
            _vm.property
          )?_c('div',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInDown'),expression:"'slideInDown'"}],staticClass:"alertDemo lg",staticStyle:{}},[_c('span',{staticStyle:{"text-align":"left","float":"left","margin-left":"21px"}},[(
                _vm.calculateRemainingDays(_vm.user?.servicios?.created_at) > 0
              )?[_vm._v(" "+_vm._s(_vm.$t("empresa.home.p1"))+" "+_vm._s(_vm.$t("empresa.home.p2"))+" "),_c('strong',[_vm._v(_vm._s(_vm.calculateRemainingDays(_vm.user?.servicios?.created_at)))]),_vm._v(" "+_vm._s(_vm.$t("empresa.home.p3"))+" ")]:(
                _vm.calculateRemainingDays(_vm.user?.servicios?.created_at) <= 0
              )?[_c('span',{staticStyle:{"color":"#eee"}},[_vm._v(_vm._s(_vm.$t("empresa.home.p4")))])]:_vm._e()],2),_c('i',{staticClass:"fa-regular fa-xmark ml-3",staticStyle:{"width":"20px","height":"20px","background":"#fff","border-radius":"50px","right":"0","float":"right","cursor":"pointer","color":"#333"},on:{"click":function($event){$event.preventDefault();return _vm.removeMessage()}}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }