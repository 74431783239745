export const integraciones = {
    text1: "Integraciones.",
    text2: "Contacte a los desarrolladores para hacer alguna integración con tus",
    text3: "PMS",
    text4: "Contactar",
    text5: "Solicitar Integración",
    text6: "Datos recolectados",
    text7: "Informacion del solicitante",
    text8: "Nombre",
    text9: "Motivo de la solicitud",
    text10: "Escribe el motivo por el cual necesitas la integración",
    text11: "Cerrar",
    text12: "Enviar solicitud de integración",
    text13: "Registrado correctamente",
    text14: "Error de solicitud",
    text15: "null",
    text16: "null",
    text17: "null",
    text18: "null",
    text19: "null",
    text20: "null",
};