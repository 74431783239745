<template>
<div class="d-flex flex-row menu">

    <div class="item-menu mr-2">
        {{ $t("empresa.maqueta.text38") }}
    </div>

    <div class="item-menu mr-2">
        {{ $t("empresa.maqueta.p1") }}
    </div>

    <div class="item-menu mr-2">
        {{ $t("empresa.maqueta.text39") }}
    </div>

    <div class="item-menu">
        Comunicaciones masivas
    </div>

</div>
</template>

<script>
export default {
    name: 'menuDashboard'
}
</script>

<style lang="scss" scoped>
.menu {
    justify-items: center;

    .item-menu {
        background: #f0f0f2;
        padding-block: 3px;
        padding-inline: 14px;
        border-radius: 8px;
    }
}
</style>
