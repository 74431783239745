<template>
  <div id="main d-flex " class="ITYKe">
    <Sidebar />
    <div
      class="contentPrincipal _U5f7"
      style="width: 100%; position: relative; margin-left: 229px"
    >
      <Nav />
      <div class="_M9xA oTNJ4" style="text-align: left">
        <div class="Kl1W_">
          <div class="YHE4L">
            <div class="uqnBi">
              <div class="SfMac d-flex flex-column">
                <div class="contenedor mt-5">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "./Sidebar";
import Nav from "./Nav";

export default {
  components: {
    Sidebar,
    Nav,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.SfMac {
  display: flex;
  flex: 1 1 100%;
}

.uqnBi {
  display: flex;
  flex: 1 0 auto;
}

.YHE4L {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  margin: auto;
  padding-top: 59px;
}

.Kl1W_ {
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  width: 100%;
}

._M9xA {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  overflow: auto;
  position: relative;
  width: 100%;
}

._M9xA > * {
  flex: 1 1 auto;
}
.oTNJ4 {
  background: #fff;
}

.ITYKe {
  display: flex;
  flex: 1 1 auto;
}

._M9xA {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;
}

._M9xA > * {
  flex: 1 1 auto;
}

._U5f7 {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
