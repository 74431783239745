export const settingAccommodation = {
    p1:`Datos básicos`,
    p2:`Ingresa el nombre y el logo que verán los usuarios al iniciar su proceso de check-in. Si no tienes un logo, se usará el de WACheckin por defecto.`,
    p3:`Políticas del alojamiento`,
    p4:`Personaliza las políticas de tu alojamiento. Estas serán las condiciones que los huéspedes aceptarán al hacer check-in.`,
    p5:`ESPAÑOL`,
    p6:`INGLES`,
    p7:`Mensaje de bienvenida`,
    p8:`Personaliza el mensaje de bienvenida que tus huéspedes verán al terminar su proceso de check-in.`,
    p9:`Claves de habitaciones`,
    p10:`Registra y gestiona las habitaciones equipadas con cerradura inteligente. Configura los códigos de acceso que se proporcionarán a los huéspedes después de realizar el check-in para facilitar su entrada.`,
    p11:`Autoridades competentes`,
    p12:`Configura la conexión con las autoridades locales para el envío automático del parte de viajeros. Ingresa el usuario y contraseña correspondientes a la plataforma de cada autoridad competente.`,
    p13:`Guardia Civil`,
    p14:`Policía Nacional`,
    p15:`Ertzaintza`,
    p16:`Mossos`,
    p17:`datos de acceso`,
    p18:`La entidad es requerida`,
    p19:`El nombre de usuario es requerido`,
    p20:`Debe ingresar la contraseña`,
    p21:`Error al intentar validar los datos`,
    p22:`La entidad es requerida`,
    p23:`El nombre de usuario es requerido`,
    p24:`Debe ingresar la contraseña`,
    p25:`Datos actualizados con éxito`,
    p26:`Error al ingresar los datos de la policía migratoria`,
    p27:`Descarga el QR de tu alojamiento para check-ins rápidos, obtén el enlace para enviar directamente a los huéspedes.`,
    p28:`Editar código de seguridad del alojamiento`,
    p29:`Verificamos la identidad de los huéspedes comparando la fotografía de su documento de identidad o pasaporte con un selfie en tiempo real.`,
    p30:`Al activar esta opción, se requerirá que los huéspedes verifiquen su identidad durante el proceso de check-in. Si la validación no se completa satisfactoriamente, no podrán proceder con el check-in.`,
    p31:`Error al activar verificación la  de identidad`,
    p32:`Ajustes de cobros`,
    p33:`Realiza cobros a los huéspedes durante el proceso de check-in, permitiéndoles pagar por servicios adicionales y tarifas aplicables.`,
    p34:`Código de seguridad del alojamiento`,
    p35:`Alojamiento`,
    p36:`Activa esta opción para enviar automáticamente un mensaje por WhatsApp el día del check-out, solicitando a los huéspedes que califiquen su experiencia en el alojamiento del 1 al 5.`,
    p37:`Ingresa la URL del sitio de reseñas que quieres configurar para que tus huéspedes dejen sus opiniones, ya sea en Google o en otro sitio de reseñas que uses. Esto ayuda a mejorar la visibilidad y reputación del alojamiento.`,
    p38:`Ingresa la URL del sitio de reseñas (ej. Google, TripAdvisor)`,
    p39:`Gestiona tu alojamiento`,
    p40:`Datos Básicos`,
    p41:`Políticas del alojamiento`,
    p42:`Mensaje bienvenida`,
    p43:`QR del alojamiento`,
    p44:`Cobros a huéspedes`,
    p45:`Cargar logo del alojamiento`,
    p46:`Puedes subir o arrastrar una imagen en el recuadro`,
    p47:`Diseño`,
    p48:`Políticas`,
    p49:`Bienvenida`,
    p50:`Región`,
    p51: `Español`,
    p52: `Inglés`,
    p53: `Árabe`,
}