<template>
<div>
    <nav class="navbar navbar-default fixed-top" style="z-index: 999" :style="
        !$store.state.userRoot.cuenta_confirmada
          ? { top: '28px !important' }
          : {}
      ">
        <message-confirmation-account />
        <div class="container" style="padding-right: 7.6%">
                <logo-administration-panel />
                <message-account-demo />
                <div class="d-flex flex-row" style="align-items: center !important">
                <notifications-nav @eventOpenService="openService" @eventActiveModalData="activeModalData" />
                <dropdown-accommodations />
            </div>
        </div>
        <modalDatos />
    </nav>
</div>
</template>

<script>
import modalDatos from "./modalDatos.vue";
import MessageConfirmationAccount from "@/components/Template/messageConfirmationAccount.vue";
import LogoAdministrationPanel from "@/components/Template/logoAdministrationPanel.vue";
import NotificationsNav from "@/components/Template/notificationsNav.vue";
import dropdownAccommodations from "@/components/Template/dropdownAccommodations.vue";
import MenuDashboard from '@/components/Template/menuDashboard.vue';
import SubNav from "./subNav.vue";
import MessageAccountDemo from '@/components/Template/messageAccountDemo.vue';

export default {
    name: "navClient",
    components: {
        modalDatos,
        MessageConfirmationAccount,
        LogoAdministrationPanel,
        NotificationsNav,
        dropdownAccommodations,
        MenuDashboard,
        SubNav,
        MessageAccountDemo
    },
    mounted() {
        this.$funtion.Notifications();
       
    },
    data() {
        return {
            mostrar: null,
        };
    },
    methods: {
        activeModalData(id) {
            console.log(id)
            this.$funtion.modalDatosGeneral(id);
        },
        openService(id) {
            console.log(id)
            localStorage.setItem("service_id", null);
            window.location.href = "/business/service?service_id=" + id;
        },
    }
};
</script>

<style lang="scss" scoped>
@media (max-width: 720px) {
    .selectCuenta {
        position: absolute;
        right: -23px;
        top: -27px;
    }

    .menuPopap {
        width: 301px !important;
    }

    .noV {
        display: none !important;
    }

    .bloquePropie {
        min-height: 354px !important;
    }
}

.dropdown-menu .dropdown-item:active {
    color: #fff !important;
}

.dropdown-item {
    color: #525252 !important;
    font-weight: 500 !important;
    margin-block: 0px !important;

    i {
        font-size: 20px;
    }
}

.titleProperty {
    font-weight: 600;
    font-size: 19px;
    color: #3b3b3b;
    text-transform: uppercase;
    text-align: center;
    margin-top: 22px;
}

.menuPopap {
    width: 400px;
    border-radius: 20px !important;
    max-height: 670px !important;
    box-shadow: 0px 0px 27px 3px #0000001c !important;
    margin-top: 10px;
}

.imageFavicon {
    height: 42px;
    width: 42px;
    border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-color: #eee;
    background-position-y: -2px;
}

.nameCount {
    text-transform: uppercase;
    font-weight: 700;
    color: #505050;
}

.inactive {
    display: none !important;
}

.icon-leng {
    &:hover {
        transition: 0.4s;
        color: rgb(121, 186, 22);
    }
}

.dropdown-item {
    cursor: pointer;
}

@media (max-width: 768px) {
    .notification {
        margin-right: 19px !important;
        margin-top: 14px !important;
    }

    .notification-icon {
        color: red;
        position: absolute;
        font-size: 8px;
        margin-left: 14px;
        margin-top: 12px !important;
    }
}

.notifications-hover:hover {
    transition: 0.3s;
    background: #fafafa;
}

.cabezera-noti {
    border-bottom: 1px solid #eee;
    padding: 11px 20px;
    font-size: 18px;
}

.ContextualPopover-arrow {
    z-index: 1;
    width: 21px;
    height: 21px;
    margin: -8px;
}

.dropdown-menu .dropdown-item {
    i {
        width: 25px;
    }
}

.dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 0.5rem 1rem;
    font-weight: bold;
    margin-left: 9px;
    color: #5e72e4;
    width: fit-content;
    margin-left: 9px;
    border-radius: 34px;
    margin-bottom: 12px;

    &:hover {
        transition: 0.2s;
        width: fit-content;
        margin-left: 9px;
        border-radius: 34px;
    }

    &:active {
        text-decoration: none;
        color: #fff;
        background-color: #5e72e4;
        border-radius: 34px;
    }
}

.dropdown-menu.user {
    min-width: 17rem;
}

.dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.navbar {
    // background: white;
    background: #fff;
    height: 66px;
    box-shadow: 0px 0px 6px 2px #f0f0f2;
    // border-bottom: 1px solid #eee;
}
</style>
