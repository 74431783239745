<template>
  <div class="main-app" style="height: auto">
    <Loader v-if="$store.state.loader" />
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Loader from "../../../components/Loaders/loaderCarga.vue";

export default {
  props: ["propiedad_id"],
  components: {
    Loader,
  },
  async mounted() {
    this.$store.state.loader = true;
    try{
     await this.getProperty(this.propiedad_id);
    }catch(error){
       toastr.error("Error load property");
    }
    finally{
      this.$store.state.loader = false;
    }
    
  },
  data() {
    return {
      propiedad: null,
    };
  },
  methods: {
    ...mapActions('property',['getProperty']),
  },
};
</script>
<style lang="scss">

.header img {
  width: 150px;
  margin: auto;
  display: block;
  margin-top: 13px;
  padding-bottom: 10px;
}
</style>
