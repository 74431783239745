<template>
<div id="app">
    <audio id="audio" src="/notification.mp3" muted="true"></audio>

    <notifications position="top right" group="foo" class="notificacion-app lg" style="width: 417px; top: 21px; right: 24px">
        <template slot="body" slot-scope="{ item, close }">
            <div class="vue-notification-template notfClas d-flex flex-row" @click="onClick(item.data)">
                <div class="icono"><i class="far fa-check-circle"></i></div>
                <div class="contenido d-flex flex-column" style="width: 88%; margin-left: 21px">
                    <p class="title">{{ item.title }}</p>
                    <div class="body" v-html="item.text" />
                </div>
                <div class="cerrar">
                    <button class="close" @click="close">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
            </div>
        </template>
    </notifications>
    <modalDatos />

    <loaderCarga v-if="$store.state.loader" />
    <router-view />
</div>
</template>

<script>
import loaderCarga from "./components/Loaders/loaderCarga.vue";
import SocketioService from "./services/socketio.service.js";
import modalDatos from "./views/Template/Maqueta/modalDatos.vue";
import './App.scss'
export default {
    components: { loaderCarga, modalDatos },
    created() {
      SocketioService.setupSocketConnection();
    },
    beforeUnmount() {
        SocketioService.disconnect();
    },
    data() {
        return {
            mensaje: "",
            nick: "",
            minombre: "",
            recibe: "",
        };
    },
    mounted() {
        // eslint-disable-next-line no-undef
        $(window).on("click", function () {});
        if (this.$store.state.userRoot) {
            // this.listarUser();
            SocketioService.socket.on("chat-message-" + this.$store.state.userRoot.id, (msg) => {
                // eslint-disable-next-line no-undef
                $("#audio").prop("muted", "");
                // eslint-disable-next-line no-undef
                $("#audio")[0].play();
                this.$funtion.Notifications();
                this.$store.state.state_notificacion = true;
                this.$notify({
                    title: msg.title,
                    text: msg.mensaje,
                    data: msg,
                    group: "foo",
                    duration: 5000,
                    classes: "notifications-active",
                });
            });
        } 
    },
    watch: {},

    methods: {

        // listarUser() {
        //     if (this.$store.state.userRoot.cuenta_confirmada) {
        //         return;
        //     }
        //     let url = this.$base.dominio + "/user/data";

        //     axios.get(url)
        //         .then((res) => {
        //             this.$store.state.userRoot.cuenta_confirmada = res.data.cuenta_confirmada;

        //             const userGetStorage = localStorage.getItem('user');
        //             const dataUserStorage = JSON.parse(userGetStorage);
        //             dataUserStorage.cuenta_confirmada = res.data.cuenta_confirmada;
        //             const setUserStorage = JSON.stringify(dataUserStorage);
        //             localStorage.setItem('user', setUserStorage);
        //             console.log('Datos del usuario guardados correctamente.');
        //         })
        //         .catch((error) => {
        //             if (error.response && error.response.data.error === 'Usuario no identificado') {
        //                 console.log('Usuario no identificado, redirigiendo al login...');
        //                 // Limpiar el estado y localStorage
        //                 this.$store.commit('logout'); // Suponiendo que tienes un método 'logout' en tu store
        //                 localStorage.removeItem('user');
        //                 // Redirigir al login
        //                 this.$router.push({ name: 'login' });
        //             } else {
        //                 console.error('Error al listar usuario:', error);
        //             }
        //         });
        // },

        activarAudio() {},
        onClick(data) {
            if (data.type == 1) {
                console.log("---onClick servicio ---");
            } else {
                console.log("---onClick checkin ---");
                this.$funtion.modalDatosGeneral(data.numero_documento);
            }
        },

    },
};
</script>
