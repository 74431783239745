export const settingAccommodation = {
    p1: `Basic Information`,
    p2: `Enter the name and logo that users will see when they start their check-in process. If you don't have a logo, the default WACheckin logo will be used.`,
    p3: `Accommodation Policies`,
    p4: `Customize your accommodation policies. These will be the conditions that guests will accept when they check in.`,
    p5: `SPANISH`,
    p6: `ENGLISH`,
    p7: `Welcome Message`,
    p8: `Customize the welcome message that your guests will see after completing their check-in.`,
    p9: `Room Keys`,
    p10: `Register and manage rooms equipped with smart locks. Configure the access codes that will be provided to guests after checking in to facilitate their entry.`,
    p11: `Competent Authorities`,
    p12: `Set up the connection with local authorities for the automatic sending of traveler reports. Enter the username and password corresponding to each competent authority's platform.`,
    p13: `Civil Guard`,
    p14: `National Police`,
    p15: `Ertzaintza`,
    p16: `Mossos`,
    p17: `access data`,
    p18: `The entity is required`,
    p19: `The username is required`,
    p20: `Password is required`,
    p21: `Error trying to validate data`,
    p22: `The entity is required`,
    p23: `The username is required`,
    p24: `Password is required`,
    p25: `Data updated successfully`,
    p26: `Error entering immigration police data`,
    p27: `Download your accommodation's QR code for quick check-ins, get the link to send directly to guests.`,
    p28: `Edit accommodation security code`,
    p29: `We verify guests' identity by comparing the photograph of their ID or passport with a real-time selfie.`,
    p30: `By enabling this option, guests will be required to verify their identity during the check-in process. If validation is not completed successfully, they will not be able to proceed with the check-in.`,
    p31: `Error activating identity verification`,
    p32: `Payment Settings`,
    p33: `Charge guests during the check-in process, allowing them to pay for additional services and applicable fees.`,
    p34: `Accommodation security code`,
    p35: `Accommodation`,
    p36: `Enable this option to automatically send a WhatsApp message on the check-out day, asking guests to rate their experience at the accommodation from 1 to 5.`,
    p37: `Enter the URL of the review site you want to set up for your guests to leave their opinions, whether it's on Google or another review site you use. This helps improve the visibility and reputation of the accommodation.`,
    p38: `Enter the review site URL (e.g., Google, TripAdvisor)`,
    p39: `Manage your accommodation`,
    p40: `Basic Information`,
    p41: `Accommodation Policies`,
    p42: `Welcome Message`,
    p43: `Accommodation QR`,
    p44: `Guest Payments`,
    p45: `Upload accommodation logo`,
    p46: `You can upload or drag an image into the box`,
    p47: `Design`,
    p48: `Policies`,
    p49: `Welcome`,
    p50: `Region`,
    p51: `Spanish`,
    p52: `English`,
    p53: `Arabic`,
    p54:``,
    p55:``,
    p56:``,
    p57:``,
    p58:``,
    p59:``,
    p60:``,
    p61:``,
    p62:``,
    p63:``,
    p64:``,
    p65:``,
    p66:``,
    p67:``,
    p68:``,
    p69:``,
    p70:``,
};
