export const account = {
    p1:`Gestiona tu cuenta`,
    p2:`Configuración de la cuenta`,
    p3:`Idioma`,
    p4:`Inicio de sesión y seguridad`,
    p5:`Mi suscripción`,
    p6:`Datos de facturación`,
    p7:`WhatsApp Business`,
    p8:`Integraciones`,
    p9:`Configuración de la cuenta`,
    p10:`Gestionar los datos de contacto donde recibirás todas las comunicaciones de WACheckin.`,
    p11:`Datos actualizados con éxito`,
    p12:`Idioma de la plataforma`,
    p13:`Selecciona el idioma de preferencia para tu panel de control. Esta configuración no afecta el idioma del check-in que reciben los huéspedes, ya que eso depende de la configuración de sus dispositivos.`,
    p14:`Seleccionar idioma de la lista`,
    p15:`Seleccionar idioma`,
    p16:`Español`,
    p17:`Ingles`,
    p18:`Actualizar idioma`,
    p19:`Idioma aplicado con éxito`,
    p20:`Aquí puedes actualizar tu contraseña para mantener tu cuenta segura.`,
    p21:`¿Quieres cambiar tu contraseña?`,
    p22:`Cambiar contraseña`,
    p23:`Revise su correo`,
    p24:`Para continuar con el cambio de contraseña consulte su bandeja de entrada y siga los pasos`,
    p25:`Continuar`,
    p26:`Ups! ocurrió un error inesperado`,
    p27:`Datos actualizados con éxito`,
    p28: `WhatsApp for Business`,
    p29: `Conecta tu cuenta de WhatsApp Business a nuestra plataforma y envía mensajes directamente a tus clientes desde tu número personalizado.`,
    p30: `Ninguna cuenta conectada.`,
    p31: `Conectar Cuenta`,
    p32: `Cuenta`,
    p33: `Conectada`,
    p34: `Desconectar Cuenta`,
    p35: `Cargando información...`,
    p36: `Desconectar WhatsApp for Business`,
    p37: `¿Estás considerando desconectar WhatsApp for Business y dejar de enviar mensajes a tus clientes a través de WhatsApp?`,
    p38: `Desconectar`,
    p39: `Cuenta de WhatsApp business desconectada con éxito`,
    p40: `Cuenta de WhatsApp business no se pudo desconectar, póngase en contacto con soporte`,
    p41: `Número de teléfono`,
    p42: `Cuenta de facebook`,
    p43: `Detalles del negocio`,
    p44: `Debe tener un número de teléfono válido y único para usarlo con la API de WhatsApp Business.`,
    p45: `Recibirá un código de verificación para autenticar su número en nuestra plataforma.`,
    p46: `El número de teléfono de su empresa debe ser un número de teléfono válido que cumpla los criterios de la`,
    p47: `plataforma de WhatsApp para empresas.`,
    p48: `Recibirá un código de verificación para autenticar su número en nuestra plataforma.`,
    p49: `Regresar al estado de la cuenta`,
    p50:`Continuar`,
    p51:`Debe tener una cuenta personal de Facebook (vinculada al e-mail de su empresa)`,
    p52:`Si tiene una cuenta de Facebook Business creada y conectada a este sistema, debe autenticarla antes de iniciar el proceso de incorporación haciendo clic en Iniciar sesión con Facebook.`,
    p53:`Para autenticar la cuenta de su empresa, abra`,
    p54:`este enlace`,
    p55:`en una pestaña independiente.`,
    p56:`Debe generar un código en su app de autenticación e introducirlo en el campo "Introducir código".`,
    p57:`Cuando lo haya confirmado, podrá cerrar esta pestaña.`,
    p58:`Regresar al estado de la cuenta `,
    p59:`Regresar`,
    p60:`Continuar`,
    p61:`Prepare los detalles de su negocio`,
    p62:`Debe tener la información de su negocio a mano: el nombre legal y la dirección de su empresa, así como la cuenta de su empresa tal y como quiera que se muestre.`,
    p63:`Debe tener un sitio web de empresa válido.`,
    p64:`Es necesario que verifique su cuenta con Meta lo antes posible. Una empresa verificada puede empezar enviando 1000 mensajes al día. Si no verifica su cuenta, Meta la desactivará.`,
    p65:`Según la política de Meta, una cuenta comercial no verificada solo se puede vincular a dos números. Verifique la cuenta para vincularla a más números.`,
    p66:`Regresar al estado de la cuenta`,
    p67:`Regresar`,
    p68:`Continuar`,
    p69:`Cuenta de WhatsApp business conectada con éxito`,

    p70:`Facturación`,
    p71:`Estado`,
    p72:`Valor cobrado`,
    p73:`Pagado`,
    p74:`Pendiente`,
    p75:`Ver factura`,
    p76:`No existen registros de facturación`,
    p77:`Fecha`,
    p78:``,
    p79:``,
    p80:``,
}