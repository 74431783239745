<template>
<div class="navMovil" v-animate-css="'slideInUp'">
    <div class="menuMobile">
        <div class="Superior d-flex flex-row">
            <div class="titleNav">Menu</div>
            <div class="iconoClose d-flex" @click.prevent="$parent.navM = false">
                <i class="fa-solid fa-xmark" style="font-size: 15px"></i>
            </div>
        </div>
        <br>
        <div class="itemMenu d-flex flex-row" :class="{'active': $route.name =='Bookings'}" @click.prevent="cambiarRuta('Bookings')">
            <i class="fas fa-check-circle mr-2"></i>
            <div class="di">Check-ins</div>
        </div>

        <div class="itemMenu d-flex flex-row" :class="{'active': $route.name =='Reservas'}" @click.prevent="cambiarRuta('Reservas')">
            <i class="fa-solid fa-clock mr-2"></i>
            <div class="di">Check-ins Prog.</div>
        </div>

        <div class="itemMenu d-flex flex-row" :class="{'active': $route.name =='Servicios'}" @click.prevent="cambiarRuta('Servicios')">
            <i class="fas fa-headset mr-2"></i>
            <div class="di">Peticiones</div>
        </div>

        <div class="itemMenu d-flex flex-row"  @click.prevent="$parent.logout" :class="{'active': $route.name =='Servicios'}">
          <i class="fa-regular fa-arrow-right-from-bracket mr-2"></i>
            <div class="di"> {{ $t("empresa.maqueta.text13") }}</div>
        </div>

    </div>

    <div class="Superior d-flex flex-row">
        <div class="titleNav">{{ $t('lg.nav.p9') }}</div>

    </div>
    <div class="Cuerpo">
        <div class="bloquePropie" style="overflow: auto; height: 100vh; padding-inline: 20px; padding-bottom: 100px">
            <div class="propiedadSelects d-flex flex-row" style="
            align-items: center;
            margin-block: 10px;
            cursor: pointer;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
          " v-for="(item, index) in $parent.properties" :key="index" @click.prevent="$parent.handlePropertySelect(item.id)">
                <div class="imagenPropiedad">
                    <div v-if="item.logo" class="imageFavicon" style="
                height: 50px;
                width: 50px;
                border-radius: 50px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                box-shadow: 0px 0px 2px 2px #eee;
                margin-top: 10px;
              " :style="{
                'background-image': `url(${$base.server + item.logo})`,
              }"></div>
                    <div v-if="!item.logo" class="imageFavicon d-flex" style="
                height: 50px;
                width: 50px;
                border-radius: 50px;
                opacity: 0.7;
                background: #f9f9f9;
                background-repeat: no-repeat;
                background-size: cover;
                box-shadow: 0px 0px 2px 2px #eee;
                margin-top: 10px;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 20px;
              ">
                        {{ item.nombre.substring(0, 1) }}
                    </div>
                </div>
                <div class="nombrePropiedad" style="font-weight: 700; text-transform: uppercase; margin-left: 13px">
                    {{ $parent.truncatedName(item.nombre) }}
                </div>
            </div>
            <!-- <div class="menuCerra">
                <a class="dropdown-item d-flex" style="
              align-items: center;
              height: 39px;
              background: #eff2f2;
              font-weight: 500;
              color: #333;
            " href="#" @click.prevent="$parent.logout">
                    <i class="fa-regular fa-arrow-right-from-bracket mr-2"></i>
                    {{ $t("empresa.maqueta.text13") }}
                </a>
            </div> -->
        </div>
    </div>
</div>
</template>

<script>
export default {
    methods: {
        cambiarRuta(ruta) {
            if (this.$route.name != ruta) {
                this.$parent.navM = false;
                this.$router.push({ name: ruta })

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.itemMenu {
    text-align: left;
    padding-left: 18px;
    margin-top: 17px;
    font-size: 15px;
    margin-bottom: 30px;

    .di {
        text-transform: uppercase;
        font-weight: 600;
    }
}

.propiedadSelects {
    &:active {
        background: #f9f9f9;
        padding-inline: 5px;
        transition: 0.3s;
    }
}

.Superior {
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid #eee;
    align-items: center;
    padding-inline: 20px;

    .titleNav {
        font-weight: 700;
        font-size: 20px;
    }

    .imageFavicon {
        height: 42px;
        width: 42px;
        border-radius: 50px;
        background-position: center;
        background-size: cover;
        background-color: #eee;
        background-position-y: -2px;
        background-size: cover;
    }

    .nameCount {
        text-transform: uppercase;
        font-weight: 700;
        color: #505050;
    }

    .iconoClose {
        height: 40px;
        width: 40px;
        border-radius: 50px;
        box-shadow: 0px 0px 2px 2px #eee;
        justify-content: center;
        align-items: center;
    }
}

.navMovil {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9000;
}
</style>
