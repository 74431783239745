export const listbookings = {
    text1: "¡Te damos la bienvenida!",
    text2: "Listado de check-in, gestiona o envía link para el registro de tus huéspedes.",
    text3: "Listado check-in",
    text4: "Exportar",
    text5: "Nuevo check-in",
    text6: "Todos",
    text7: "Pendiente",
    text8: "Completos",
    text9: "Hoy",
    text10: "Teléfono",
    text11: "Nombre",
    text12: "Documento",
    text13: "País",
    text14: "Check-In",
    text15: "Llaves",
    text16: "Entregado",
    text17: "En espera",
    text18: "Ver Información completa",
    text19: "Documento firmado",
    text20: "Todos",
    text21: "Existe un error en el proceso de confirmación de entrega",
    text22: "Información del check-in",
    text23: "Datos recolectados en el check-in del huésped",
    text24: "Nombre",
    text25: "Apellido",
    text26: "Teléfono",
    text27: "Fecha de hospedaje",
    text28: "Tipo de documento",
    text29: "Pasaporte",
    text30: "Documento nacional de identidad",
    text31: "Número de documento",
    text32: "Expiración de documento",
    text33: "Nacionalidad",
    text34: "Fecha de nacimiento",
    text35: "Genero",
    text36: "Femenino",
    text37: "Masculino",
    text38: "Escribe aquí tus ...",
    text39: "Hola [nombre_cliente],\nBienvenido al HOTEL BARCELO MALAGA!\n\nMuchas gracias por alojarte con nosotros, te informamos que la hora de salida es hasta las 10.00 AM.\n\nAdemás te informamos los datos de acceso a nuestra red Wifi:\n\nRed: HOTEL-BARCELO\nClave: xkamakj1a0\n\n\nEsperamos que su estancia sea de lo mejor posible.\n\nEstamos a su disposición en recepción las 24horas.\n\nUn saludo!",
    text40: "El nombre de tu alojamiento es obligatorio.",
    text41: "Las políticas del alojamiento son requeridas.",
    text42: "Debe incluir un mensaje de bienvenida.",
    text43: "Cambios guardados con éxito",
    text44: "Error de solicitud",
    text45: "Registrada con éxito.",
    text46: "😃 Hola!! ",
    text47: " Completaste el proceso de crear tu primer alojamiento con éxito, ahora puedes compartir tu QR para que los usuarios puedan hacer ",
    text48: "check-in",
    text49: "automatizado 👍",
    text50: "Puedes editar los campos ingresados anteriormente desde el menú",
    text51: "Configuración de plantilla.",
    text52: "Descargar",
    text53: "Continuar",
    text54: "Información del registro",
    text55: "Datos de check-in",
    text56: "Fecha de la solicitud:",
    text57: " a las",
    text58: "Datos recolectados en el check-in del huésped",
    text59: "Nombre",
    text60: "Apellido",
    text61: "Teléfono",
    text62: "Fecha de hospedaje",
    text63: "Tipo de documento",
    text64: "Pasaporte",
    text65: "Documento nacional de identidad",
    text66: "Número de documento",
    text67: "Información adicional",
    text68: "Expiración de documento",
    text69: "Nacionalidad",
    text70: "Fecha de nacimiento",
    text71: "Genero",
    text72: "Femenino",
    text73: "Masculino",
    text74: "Cerrar",
    text75: "Nuevo Check-In",
    text76: "Puedes enviar el link o crear un check-in manual en las siguientes opciones. ",
    text77: "Check-In",
    text78: "Check-In manual desde el panel administrativo.",
    text79: "Enviar Check-In",
    text80: "Link de reserva a un huésped.",
    text81: "Cargando por favor espere...",
    text82: "Años",
    text83: "Días",

    // Modal exportar 
    text84:`EXPORTAR`,
    text85:`Exportar informes en WACheckin`,
    text86:`Descargar Informe de checkin`,
    text87:`Descarga los check-in realizados por tus huéspedes y obtén un
    informe detallado con información como la fecha de entrada, entre
    otros datos.`,
    text88:`Descargar de informe de migración`,
    text89:`Si la sincronización automática a la entidad migratoria falla
    puedes descargar este informe e ingresar de forma manual los
    datos de tu huésped.`,


    
    text90:`Entregadas Llaves`,
    text91:`Autoridades`,
    text92:`Descargar Parte policía`,
    text93:`Eliminar check-in`,
    text94:`Aún no hay check-ins en tu alojamiento`,
    text95:`Aquí aparecerán los huéspedes registrados`,
    text96:`EXPORTAR`,
    text97:`EXPORTAR`,
    text98:`EXPORTAR`,

//    Correciones 
   
   p1:`Enviar`,
   p2:`Link de Check-in`,
   p3:`Escanear`,
   p4:`El huésped firmará en este dispositivo`,
   p5:`Escaneado`,
   p6:`Manual`,
   p7:`Manual operador`,
   p8:`check-ins`,
   p9:`Utiliza la opción de check-in manual para registrar a tus huéspedes directamente desde la recepción`,
   p10:`Check-in manual`,
   p11:`Envía el enlace de check-in online a tus huéspedes a través de WhatsApp o E-mail para facilitar su registro antes de su llegada`,
   p12:`Enviar check-in online`,
   p13:`Sin especificar`,
   p14:`Eliminar check-in`,
   p15:`¿Estás seguro de eliminar el check-in? No podrás recuperar los datos eliminados`,
   p16:`Eliminar`,
   p17:`Cancelar`,
   p18:`Desbloquea todo el potencial con nuestras funciones premium`,
   p19:`Auto check-in por WhatsApp`,
   p20:`Envió parte de viajeros`,
   p21:`Verificación de identidad`,
   p22:`Valoración automática`,
   p23:`Ventas cruzadas`,
   p24:`Gestión claves habitantes`,
   p25:`Cobros a huéspedes`,
   p26:`¡No pierdas los beneficios!`,
   p27:`Actualizar a Premium`,

   p28:`Concepto`,
   p29:`Cobro adicional`,
   p30:`Estado`,
   p31:`Pagado`,
   p32:`Pendiente`,
   p33:`Rembolsado`,
   p34:`Reembolsar cobro`,
   p35:`Reembolsar`,
   p36:`¿Estas seguro de reembolsar el cobro al huésped?`,
   p37:`Documento de identidad`,
   p38:`Pasaporte`,
   p39:`FILTRAR`,
   p40:`Check-ins realizados`,
   p41:`Check-ins en proceso`,
   p42:`No tienes check-ins en proceso`,
   p43:`Información del check-in`,
   p44:`Check-ins asociados`,
   p45:`No hay check-in asociados.`,
   p46:`Más información:`,
   p47:`Fecha de entrada:`,
   p48:`Fecha de salida:`,

   p49:`Huéspedes`,
   p50:`Huésped`,
   p51:``,

};