export const profile = {
    text1: "Datos de mi cuenta",
    text2: "Información personal.",
    text3: "Nombre",
    text4: "Número de Identidad",
    text5: "Teléfono",
    text6: "País",
    text7: "Correo Electrónico",
    text8: "Actualizar datos",
    text9: "Datos de acceso.",
    text10: "Contraseña",
    text11: "Confirmar Contraseña",
    text12: "Actualizar contraseña",

    // Correciones 
    p1:`Configuración de la cuenta`,
    p2:`Datos del perfil`,
    p3:`Inicio de sesión y seguridad`,
    p4:`Datos de facturación`,
    p5:`Dirección de correo electrónico principal`,
    p6:`Aquí es donde recibirás todas las comunicaciones de WACheckin`,
    p7:`Datos de la cuenta`,
    p8:`Gestiona los datos de tu cuenta.`,
    p9:`Nombre`,
    p10:`Ingresa el nombre completo`,
    p11:`Número de identidad`,
    p12:`Ingresa el número de identidad`,
    p13:`Teléfono`,
    p14:`Ingresa el teléfono`,
    p15:`Inicio de sesión y seguridad`,
    p16:`Actualiza tu contraseña y protege tu cuenta.`,
    p17:`Contraseña de la cuenta`,
    p18:`Última actualización:`,
    p19:`Cambiar`,
    p20:`Datos de facturación la cuenta`,
    p21:`Gestiona los datos de tu cuenta.`,
    p22:`Razón social`,
    p23:`Ingresa la razón social`,
    p24:`NIF/CIF`,
    p25:`Ingresa el número de NIF/CIF`,
    p26:`Dirección de facturación`,
    p27:`Ingresa la dirección de facturación`,
    p28:`País`,
    p29:`Provincia`,
    p30:`Código postal`,
    p31:`No tiene datos de facturación`,
    p32:`Los datos de facturación se cargan automáticamente cuando realiza su suscripción.`,
    p33:``,
    p34:``,
    p35:``,
    p36:``,
    p37:``,
    p38:``,
    p39:``,
    
};