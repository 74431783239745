<template>
<div v-if="
            demo &&
            user &&
            user?.servicios &&
            !user?.suscription_id_stripe &&
            property
          " class="alertDemo lg" v-animate-css="'slideInDown'" style="
            
          ">
    <span style="text-align: left; float: left; margin-left: 21px"><template v-if="
                calculateRemainingDays(user?.servicios?.created_at) > 0
              ">
            {{ $t("empresa.home.p1") }} {{ $t("empresa.home.p2") }}
            <strong>{{
                calculateRemainingDays(user?.servicios?.created_at)
              }}</strong>
            {{ $t("empresa.home.p3") }}
        </template>
        <template v-else-if="
                calculateRemainingDays(user?.servicios?.created_at) <= 0
              ">
            <span style="color: #eee">{{ $t("empresa.home.p4") }}</span>
        </template></span>
    <i @click.prevent="removeMessage()" style="
              width: 20px;
              height: 20px;
              background: #fff;
              border-radius: 50px;
              right: 0;
              float: right;
              cursor: pointer;
              color: #333;
            " class="fa-regular fa-xmark ml-3"></i>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'messageAccountDemo',
    computed: {
        ...mapState('user', ['user']),
        ...mapState(['demo']),
        ...mapState('property', ['property']),
    },
    methods: {

        removeMessage() {
            this.$store.state.demo = false;
        },

        calculateRemainingDays(fecha) {
            const created_at = this.moment(fecha); // fecha de registro
            const current_date = this.moment(); // fecha actual
            const days_elapsed = current_date.diff(created_at, "days"); // dias transcurridos
            const remaining_days = 14 - days_elapsed;
            return remaining_days; // dias restantes
        },
    }

}
</script>

<style lang="scss" scoped>
.alertDemo {
    background: #cd1212;
    padding-inline: 3px;
    padding-block: 3px;
    border-radius: 15px;
    color: #fff;
    font-weight: 500;
    margin-top: -3px;
    margin-left: auto;
    width: 57%;
}
</style>
