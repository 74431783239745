
import  axios  from 'axios';
import { dominio } from '../../config/base';

const url = dominio;

export default {
    async regionAvailable({commit}, country_code) {
        if (country_code === 'CO' || country_code === 'ES') {
            return true;
        } else {
            return false;
        }
    },

    async getProperty({commit}, id_accommodation){
        console.log('___getProperty____')
        const dataSend ={
            id_accommodation:id_accommodation
        }
        try{
            const result = await  axios.post(url + '/get-property', dataSend);
            commit('SET_PROPERTY', result);
            commit('user/SET_USER', result.data?.user, {root: true});
            return result.data;
        }catch(error){
            console.log(error);
        }
       
    },

    async getProperties({commit}, user_id){
        const sendData = {
           user_id
        }
        try{
            const response = await axios.post(url + '/retrive-properties', sendData);
            commit('SET_PROPERTIES', response.data);
            return response.data;
        }catch(error){
            console.log("error => ", error)
        }
    }
}