import axios from "axios";
import { dominio } from "../../config/base";
import toastr from "toastr";

const url = dominio;

export default {
   
  async retriveUser({commit}, user_id){
    try {
      const response  = await axios.get(url + '/retrive-user-' + user_id);
      commit('SET_USER_RETRIVE', response.data);
      return response.data;
    }catch(error){
      toastr.error('Error al completar la solicitud retriveUser');
    }
  },

  async getUserAuth({ commit }) { // obtener user logueado 
    try {
      const result = await axios.get(url + '/user');
      commit("SET_USER", result.data); // Seteamos el user del store userStore;
      commit("setUserGlobal", result.data, {root: true}); // Eviamos para poder actualizar el root  
      return result.data;
    } catch (e) {
      console.log(e);
    }
  },
};
