<template>
<div>
    <Nav />
    <SubNav class="lg" />
    <!-- <TabBottom class="xs" /> -->
    <button-soport v-if="!$isMobile" />
    <div class="contenedor-main" :style="
        $route.name == 'InvoicesCompra'
          ? {
              background: '#f6f6f6',
              width: '100%',
              'min-height': '100vh',
              padding: '8%',
            }
          : {}
      ">
        <div class="container-fluid p-0">
            <router-view></router-view>
            <!-- <PaymentFail v-if="addMethod" /> -->
        </div>
    </div>

    <modal-whatsapp />
    <ModalLenguaje />
</div>
</template>

<script>
import Nav from "./Nav.vue";
import SubNav from "./subNav.vue";
// import PaymentFail from "./PaymentFail.vue";
import ModalLenguaje from "./modalLenguaje.vue";
import TabBottom from "./TabBottom.vue";
import ModalWhatsapp from "./modalWhatsapp.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import ButtonSoport from './buttonSoport.vue';

export default {
    name: "exampleMaquetas",
    components: {
        Nav,
        SubNav,
        ModalLenguaje,
        TabBottom,
        ModalWhatsapp,
        ButtonSoport,
    },
    mounted() {
        this.getProperty(this.propiedadActiva);
        this.getUserAuth()
    },
    data() {
        return {
            addMethod: false,
        };
    },

    computed: {
        ...mapState(['propiedadActiva']),
       
    },
    methods: {
        ...mapActions('property', ['getProperty']),
        ...mapActions('user', ['getUserAuth']),
        // ...mapMutations(['setUserGlobal']),

        logout() {
            this.$store
                .dispatch("destroyToken")
                .then(() => {
                    window.location.href = "/";
                })
                .catch(() => {
                    // eslint-disable-next-line no-undef
                    toastr.error(this.$t("empresa.maqueta.text14"));
                });
        },
    },
};
</script>

<style lang="scss">
.botonGuardarDB {
    color: white !important;
    padding: 11px;
    font-size: 15px;
    float: right;
    background: rgb(17, 177, 77);
    border-radius: 10px;
    font-weight: 700;
}

@media (min-width: 768px) {
    .contenedor-main {
        padding-top: 9% !important;
        width: 83.2%;
        text-align: left;
        margin: auto;
    }
}
</style>
