export const nav = {
    p1: `You can manage your personal information, change your password, integrations, subscription, and adjust your user preferences`,
    p2: `My account`,
    p3: `Customize your accommodation details, personalize welcome messages, policies, connect with local authorities for automatic traveler report submissions, and adjust operational preferences`,
    p4: `Accommodation settings`,
    p5: `Manage and promote your accommodation's additional services, such as space rentals, tours, and other extras, to enhance your guests' experience and increase your revenue`,
    p6: `Cross-sales`,
    p7: `Create new accommodation`,
    p8: `Confirmation email sent successfully`,
    p9: `Accommodations`,
    p10: ``,
    p11: ``,
    p12: ``,
    p13: ``,
    p14: ``,
    p15: ``,
    p16: ``,
    p17: ``,
    p18: ``,
    p19: ``,
    p20: ``,
    p21: ``,
    p22: ``,
    p23: ``,
};
