export default {
  SET_PROGRAMMED(state, result) {
    if (result && JSON.stringify(state.programmed) !== JSON.stringify(result)) {
      state.programmed = result;
    } else {
      console.log("is programmed not change");
    }
  },

  SET_CLIENT(state, result) {
    const data = { ...state.client, ...result };
    console.log(data);
    state.client = data;
  },
};
