export const enviarcheckin = {
    text1: "Enviar Check-In",
    text2: "Envía un link para que tus clientes puedan hacer check-in antes de llegar al alojamiento.",
    text3: "Selecciona el alojamiento *",
    text4: "Introducir nombre",
    text5: "Nombre del cliente *",
    text6: "Número de teléfono",
    text7: "Correo electrónico",
    text8: "Correo electrónico del cliente",
    
    text9: "Enviar",
    text10: "Enviando check-in por favor espere",
    text11: "Debe seleccionar un alojamiento para asignar el check-in",
    text12: "El campo nombre es obligatorio",
    text13: "El campo teléfono es obligatorio",
    text14: "El correo electrónico es obligatorio",
    text15: "Casi terminamos",
    text16: "Hola ",
    text17: "! Somos ",
    text18: ". Te enviamos este mensaje para que puedas hacer Check-in previo en nuestro alojamiento, evitando así posibles esperas en recepción y sobre todo el consumo deliverado de papel. \n\nPor un planeta más verde! ",
    text19: "Hola ",
    text20: "! Somos ",
    text21: ". Te enviamos este mensaje para que puedas hacer Check-in previo en nuestro alojamiento, evitando así posibles esperas en recepción y sobre todo el consumo deliverado de papel. <br>  <br> Por un planeta más verde! ",
    text22: "Check-in enviado con éxito",
    text23: "Cargando ...",


    // Correcciones  
    p1:`Enviar link de check-in`,
    p2:`Programar check-in`,
    p3:`Programa invitación a tu huésped para que proceda con el checkin`,
    p4:`Nombre del huésped responsable`,
    p5:`Fecha de entrada`,
    p6:`Fecha de salida`,
    p7:`Cantidad de huéspedes`,
    p8:`Seleccione una habitación`,
    p9:`Sin especificar`,
    p10:`Selecciona número de la habitación`,
    p11:`Check-in online`,
    p12:`Precio de la reserva`,
    p13:`Programar Check-in`,
    p14:`Invita ahora a tu huésped a que proceda con el check-in`,
    p15:`Cobro adicional`,
    
    p16:`Especificar la habitación`,
    p17:`Si seleccionas una habitación le enviaremos las claves asociadas`,
    p18:`Seleccionar habitación`,
    p19:`No se encontraron resultados. Intenta con otro término.`,
    p20:`No hay habitaciones disponibles.`,
    p21:`Presiona enter para seleccionar`,
    p22:`Seleccionado`,
    p23:`Presiona enter para remover`,
    p24:`No hay opciones disponibles`,

    p25:`Reserva creada con éxito.`,
    p26:`La creación de la reserva se completo correctamente, puedes regresar al inicio.`,
    p27:`Invitación enviada con éxito.`,
    p28:`Aceptar`,
    p29:``,
    p30:``,
    p31:``,

};