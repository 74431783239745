export const checkinmanual = {
    text1: "Check-In Manual",
    text2: "Enter the data required for guest registration.",
    text3: "Accommodation Information",
    text4: "Select the accommodation *",
    text5: "Personal Information",
    text6: "Guest first name *",
    text7: "Guest last name *",
    text8: "Male",
    text9: "Female",
    text10: "Prefer not to say",
    text11: "Other",
    text12: "Select gender *",
    text13: "Date of birth *",
    text14: "Phone number *",
    text15: "Lodging Information",
    text16: "Lodging information is mandatory to proceed with the check-in. Please fill in all fields.",
    text17: "From - To",
    text18: "Until what date will the guest be staying?",
    text19: "Guest's email *",
    text20: "Select document type.",
    text21: "ID",
    text22: "Passport",
    text23: "Document type",
    text24: "Document number",
    text25: "Enter the document number",
    text26: "Document expiration",
    text27: "Document expiration",
    text28: "Continue",
    text29: "Check-In Manual",
    text30: "Enter the data required for guest registration.",
    text31: "Check-in signature",
    text32: "Sign in the gray box you see on the screen",
    text33: "Save Check-in",
    text34: "Check-in process completed successfully.",
    text35: "You must select an accommodation to assign the check-in",
    text36: "The name field is required",
    text37: "The last name field is required",
    text38: "The gender field is required",
    text39: "The date of birth field is required",
    text40: "The phone field is required",
    text41: "The lodging date field is required",
    text42: "The email field is required",
    text43: "The country field is required",
    text44: "The document type field is required",
    text45: "The document number field is required",
    text46: "You must accept the terms and conditions.",
    text47: "Sending Check-in data please wait...",
    text48: "Loading...",
    text49: "completed check-in successfully.",
    text50: "Click here to see all our available services at the hotel",
    text51: "Enter first name",
    text52: "Enter last name",
    text53: "Country",
    text54: "Error",
    text55: "Enter email address",

    // Corrections
    p1: "Check-in date",
    p2: "Check-out date",
    p3: "Document issue date",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
};
