export const nav = {
    p1:`Podrás gestionar tu información personal, cambiar tu contraseña, integraciones, suscripción y ajustar tus preferencias de usuario`,
    p2:`Mi cuenta`,
    p3:`Personalizar los detalles de tu alojamiento, personaliza mensaje de bienvenida, políticas, conecta con autoridades de tu localidad para el envío automático de parte de viajeros y ajustar las preferencias de operación`,
    p4:`Configuración del alojamiento`,
    p5:`Gestiona y promociona los servicios adicionales de tu alojamiento, como el alquiler de espacios, tours y otros extras, para mejorar la experiencia de tus huéspedes y aumentar tus ingresos`,
    p6:`Ventas cruzadas`,
    p7:`Crear nuevo alojamiento`,
    p8:`Correo de confirmación enviado con éxito`,
    p9:`Alojamientos`,
    p10:``,
    p11:``,
    p12:``,
    p13:``,
    p14:``,
    p15:``,
    p16:``,
    p17:``,
    p18:``,
    p19:``,
    p20:``,
    p21:``,
    p22:``,
    p23:``,
}