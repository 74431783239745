<template>
<div class="noV contenedorNotificaciones dropdown d-flex" style="margin-inline: 10px" :class="{ inactive: validateSubMneu() }">
    <!-- Icono notificacion   -->
    <i v-if="$store.state.state_notificacion == true" class="fas fa-circle notification-icon" style="
                color: red;
                position: absolute;
                font-size: 8px;
                margin-left: 14px;
              "></i>
    <button @click="$store.state.state_notificacion = false" type="button" class="dropdown-toggle notification cursor-pointer vs-con-dropdown parent-dropdown" style="" data-toggle="dropdown" aria-expanded="false">
        <span class="cursor-pointer mt-1 sm:mr-6 feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg></span>
    </button>

    <div class="dropdown-menu notificaciones">
        <div class="ContextualPopover-arrowContainer" style="position: absolute; right: 79px; top: -8px">
            <div class="ContextualPopover-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="9" role="presentation">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#fff" fill-opacity="0.1" d="M1 9.092h19l-6.402-6.74c-1.717-1.806-4.485-1.8-6.196 0L1 9.093zM20.342 8l-6.02-6.336c-2.108-2.22-5.538-2.218-7.645 0L.658 8h19.684z"></path>
                        <path fill="#fff" d="M7.402 2.353c1.711-1.801 4.48-1.807 6.196 0L20 9.093H1l6.402-6.74z"></path>
                    </g>
                </svg>
            </div>
        </div>

        <div class="cabezera-noti">
            <strong> {{ $t("empresa.maqueta.text6") }} </strong>
        </div>
        <template v-if="notificaciones.length > 0">
            <list-notifications />
        </template>
        <template v-else>
            <div style="padding: 28px; margin: auto; text-align: center">
                <small> {{ $t("empresa.maqueta.text7") }} </small>
            </div>
        </template>

        <div @click="closeDropdown">
        <router-link class="dropdown-item" :to="{ name: 'notificacionesUser' }" >
            {{ $t("empresa.maqueta.text8") }}
        </router-link>
    </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import listNotifications from './listNotifications.vue'
export default {
    components: { listNotifications },
    computed: {
        ...mapState(['notificaciones'])
    },
    mounted(){
        $(".dropdown-menu").on("click", function (e) {
            e.stopPropagation();
        });
    },
    methods: {
        closeDropdown(event) {
            const dropdownMenu = $(event.target).closest('.dropdown');
            dropdownMenu.find('.dropdown-toggle').dropdown('hide');
        },
        activeEvent(reason, id){
             if(reason == 'checkin'){
                this.$emit('eventActiveModalData', id)
             }else if(reason == 'service'){
                this.$emit('eventOpenService', id)
             }
        },

        validateSubMneu() {
            if (this.$route.name == "InvoicesCompra") {
                return true;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
// .noV {
//     display: none !important;
// }

.dropdown-menu.notificaciones {
    min-width: 34rem;
    float: left;
    left: -374px;
    top: 45px;
}

.contenedorNotificaciones {
    height: 42px;
    width: 42px;
    background: #eee;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
}

.dropdown-menu .dropdown-item:active {
    color: #fff !important;
}

.dropdown-item {
    color: #525252 !important;
    font-weight: 500 !important;
    margin-block: 0px !important;

    i {
        font-size: 20px;
    }
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-menu .dropdown-item {
    i {
        width: 25px;
    }
}

.dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 0.5rem 1rem;
    font-weight: bold;
    margin-left: 9px;
    color: #5e72e4;
    width: fit-content;
    margin-left: 9px;
    border-radius: 34px;
    margin-bottom: 12px;

    &:hover {
        transition: 0.2s;
        width: fit-content;
        margin-left: 9px;
        border-radius: 34px;
    }

    &:active {
        text-decoration: none;
        color: #fff;
        background-color: #5e72e4;
        border-radius: 34px;
    }
}

.dropdown-menu.user {
    min-width: 17rem;
}

.dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

@media (max-width: 768px) {
    .notification {
        margin-right: 19px !important;
        margin-top: 14px !important;
    }

    .notification-icon {
        color: red;
        position: absolute;
        font-size: 8px;
        margin-left: 14px;
        margin-top: 12px !important;
    }
}

.notifications-hover:hover {
    transition: 0.3s;
    background: #fafafa;
}

.cabezera-noti {
    border-bottom: 1px solid #eee;
    padding: 11px 20px;
    font-size: 18px;
}
</style>
